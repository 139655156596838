import React, { Component } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import axios from "axios";
import Loader from "react-loader-spinner";

//import { Link } from 'react-router-dom';
class Votation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			availiableComites: [],
			alreadyVotedComites: [],
			fetchToVoted: true,
			fetchAlreadyVote: true,
		};
	}
	getAvailiableComites = async () => {
		let formData = new FormData();

		formData.append("id_votes", this.props.idVotation);
		formData.append("id_user", this.props.idUser);
		await axios
			.post("/api/votes_comites_and_candidates_filter_already_voted", formData)
			.then((res) => {
				this.setState({
					availiableComites: res.data.records,
					fetchToVoted: false,
				});
			})
			.catch((error) => {
				this.setState({
					fetchToVoted: false,
				});
			});
	};
	getAlreadyVoted = async () => {
		let formData = new FormData();

		formData.append("id_votes", this.props.idVotation);
		formData.append("id_user", this.props.idUser);
		await axios
			.post("/api/votes_comites_voted_get_by_id_user", formData)
			.then((res) => {
				this.setState({
					alreadyVotedComites: res.data.records,
					fetchAlreadyVote: false,
				});
			})
			.catch((error) => {
				this.setState({
					fetchAlreadyVote: false,
				});
			});
	};
	componentDidMount() {
		(async () => {
			await this.getAvailiableComites();
			await this.getAlreadyVoted();
		})();
	}
	votarComite = (event) => {
		event.preventDefault();

		let formData = new FormData();
		formData.append("id_votes_candidates", event.target.userID.value);
		formData.append("id_user", this.props.idUser);
		axios
			.post("/api/votes_vote_set", formData)
			.then((res) => {
				alert("Su voto se ha guardado con exito");
				window.location.reload();
			})
			.catch((error) => {
				alert("Hemos encontrado un problema y su voto no se ha guardado");
			});
	};
	render() {
		const { fetchAlreadyVote } = this.state;
		const { fetchToVoted } = this.state;

		const allLoad = fetchAlreadyVote === false && fetchToVoted === false;
		console.log(allLoad);
		let voted;
		console.log(this.state.alreadyVotedComites);
		if (this.state.alreadyVotedComites.length > 0) {
			voted = (
				<div>
					<p>Listado de comités a los que ya ha votado:</p>
					<ul>
						{this.state.alreadyVotedComites.map((AV, index) => {
							return <li key={index}>{AV.title}</li>;
						})}
					</ul>{" "}
				</div>
			);
		}
		const allComitesToVote = this.state.availiableComites.map((AC, index) => {
			if (AC.candidates) {
				var a;
				a = 0;
				return (
					<Form
						onSubmit={this.votarComite}
						className="BoxComitePresentCan"
						key={index}
					>
						<Row>
							<Col lg={12} sm={12}>
								<p className="titleComitePresentCan center">{AC.title}</p>
							</Col>
							<Table striped bordered hover className="width-100">
								<thead>
									<tr>
										<th>Candidato/a</th>
										<th>Programa de trabajo</th>
										<th>Votar</th>
									</tr>
								</thead>
								<tbody>
									{AC.candidates.map((cand, index) => {
										if (+cand.approved === 1) {
											a = 1;
											return (
												<tr key={index}>
													<td>{cand.fullname}</td>
													<td>
														<a
															href={cand.doc}
															target="_blank"
															rel="noopener noreferrer"
														>
															Ver Programa
														</a>
													</td>
													<td>
														<Form.Check
															type="radio"
															name="userID"
															value={cand.id}
															aria-label="radio 1"
															required
														/>
													</td>
												</tr>
											);
										}
										return null;
									})}
								</tbody>
							</Table>
							{a === 1 ? (
								<Col sm={12} xs={12} className="center">
									<Button
										ref="btnCandidatura"
										variant="primary"
										type="submit"
										className="center"
									>
										Votar
									</Button>
								</Col>
							) : (
								<p className="center width-100">
									No hay candidaturas disponibles para este Comité
								</p>
							)}
						</Row>
					</Form>
				);
			} else {
				return null;
			}
		});
		return (
			<Row>
				<Col lg={12} sm={12}>
					<h4 className="center">Abierto periodo de votaciones</h4>
					<p className="center">
						Podrá votar del <b>{this.props.dateBegin}</b> al{" "}
						<b>{this.props.dateEnd}</b>.
					</p>
					<p>Instrucciones</p>
					<ul>
						<li>
							A continuación, encontrará una relación de candidaturas
							presentadas por cada comité al que esté asociado/a. Podrá votar una
							sola candidatura por comité.
						</li>
						<li>
							Una vez emitido el voto le llegará un email con la confirmación.{" "}
						</li>
						<li>
							Durante el periodo de votaciones, si el socio/a entra de nuevo en
							el apartado podrá ver lo que ha votado y seguir votando el resto
							de comités a los que tenga derecho si todavía no lo ha hecho. Si
							no ve ningún comité, quiere decir que ya ha votado todos los
							comités disponibles.
						</li>
						<li>
							Finalizado el periodo de votaciones, los resultados serán
							publicados en el apartado web de cada comité.
						</li>
					</ul>

					{allLoad === false ? (
						<div className="centerloader">
							<Loader type="Oval" color="#00BFFF" height={100} width={100} />
							<h3>Cargando información de Votación</h3>
						</div>
					) : (
						voted
					)}
				</Col>
				{allLoad === false ? (
					""
				) : (
					<Col lg={12}>
						<p className="center">
							A continuación puede votar a los comités en los que está suscrito/a,
							si no ve ningún comité eso quiere decir que ya ha votado a los
							comités disponibles.
						</p>
						{allComitesToVote}
					</Col>
				)}
			</Row>
		);
	}
}

export default Votation;
