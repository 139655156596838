import React, { Fragment } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { Table as tableBoostrapt } from 'react-bootstrap';
import { Table } from 'antd';
import TopImageTitle from '../../components/objects/topImageTitle';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import ReactGA from 'react-ga';
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  Image,
} from '@react-pdf/renderer';
import SidebarProfile from './components/sidebar';
import SEO from '../../components/objects/seo';
import { Divider } from 'antd';
import Cookies from 'js-cookie';

class ColectivoUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: [],
      islogged: 0,
      statusPage: 0,
      userOrders: [],
      pageUpdate: '',
      key: 'personalData',
      personalDataNotComplete: false,
      profesionalDataNotComplete: false,
      msgCompleteProfilePersonal: null,
      msgCompleteProfileProfesional: null,
      msgCompleteProfileSettings: null,
      msgCompleteProfile: null,
      endIban: '',
      showLinkToPDF: false,
      numComitesPagoPDF: 0,
      nameInvoice: '',
      userComitesAdmin: [],
      fetchingAdminOrder: false,
      phase: '',
      idVotation: '',
      phaseAssembly: '',
      idVotationAssembly: '',
      fetchInformation: true,
      numberIteration: 0,
      renovableOrder: [],
      orderForSidebar: [],
      comitesUserGet: [],
      userExtra: [],
      sameContact: 1,
      toColectivo: this.props.toColectivo,
      toColectivoIdType: '',
      candidatureComiteExecutive: 0,
      messageCompletetoAfilitation: '',
      allowAddComites: false
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(key) {
    this.setState({ key });
  }
  checkTokken = async () => {
    const jwt = localStorage.getItem('access_token');

    if (!jwt) {
      this.setState.islogged = 0;
    } else {
      this.setState.islogged = 1;
      let formData = new FormData();
      formData.append('jwt', jwt);
      await axios
        .post('/api/validate_token', formData)
        .then((res) => {
          this.setState({
            userInfo: res.data.data,
          });
          this.getUserExtraInfo(res.data.data.id);
        })
        .catch((error) => {
          this.setState({
            statusPage: 1,
          });
        });
    }
  };
  getUserExtraInfo = (id) => {
    let formData = new FormData();
    formData.append('id', id);
    axios
      .post('/api/users_extra_data_get', formData)
      .then((res) => {
        if (
          res.data.records[0].address2 ||
          res.data.records[0].job2 ||
          res.data.records[0].email2 ||
          res.data.records[0].lastname2 ||
          res.data.records[0].name2 ||
          res.data.records[0].phone2
        ) {
          this.setState({
            sameContact: 1,
          });
        }
        this.setState({
          userExtra: res.data.records[0],
        });
      })
      .catch((error) => {});
  };
  getOrdersUser = async () => {
    const { userInfo } = this.state;
    var userId = userInfo.id;
    let formData = new FormData();
    formData.append('id_user', userId);
    await axios
      .post('/api/payment_get_by_id_user', formData)
      .then((res) => {
        this.setState({
          userOrders: res.data.records,
          numberofOrders: res.data.records.length,
        });
        this.checkForRenew(res.data.records);
      })
      .catch((error) => {});
  };
  getComitesAdmin = async () => {
    const { userInfo } = this.state;
    var userId = userInfo.id;

    let formData = new FormData();
    this.setState({
      fetchingAdminOrder: true,
    });

    formData.append('id', userId);

    await axios
      .post('/api/users_comites_admin_get_by_id', formData)
      .then((res) => {
        this.setState({
          userComitesAdmin: res.data.records,
          fetchingAdminOrder: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchingAdminOrder: false,
        });
      });
  };
  setMessageWarning() {
    /*
		const { userInfo } = this.state;
		//Message user Personal data
		
		if (userInfo) {
			if (
				!userInfo.name ||
				!userInfo.nif ||
				!userInfo.country ||
				!userInfo.city ||
				!userInfo.region ||
				!userInfo.landline
			) {
				this.setState({
					msgCompleteProfilePersonal:
						"Es necesario que complete todos los campos marcados con asterisco en la pestaña datos del colectivo si desea realizar una afiliación.",
				});
			} else {
				this.setState({
					msgCompleteProfilePersonal: null,
				});
			}
		} else {
			this.setState({
				msgCompleteProfilePersonal: null,
			});
		}
		//Message user profesional data

		if (userInfo) {
			if (!this.state.userExtra.name1) {
				this.setState({
					msgCompleteProfileProfesional:
						"Recuerde que debe rellenar los campos marcados con asterisco en el apartado Datos de contacto",
				});
			} else {
				this.setState({
					msgCompleteProfileProfesional: null,
				});
			}
		} else {
			this.setState({
				msgCompleteProfileProfesional: null,
			});
		}
*/
    //Message user settings data
    /*
		if (userInfo) {
			if (+userInfo.newsletter === 2) {
				this.setState({
					msgCompleteProfileSettings:
						"Anotandose a recibir la newsletter de FES la cuenta asociada recibira los emails, si desea que otra cuenta reciba nuestras comunicaciones puede unirse a la newsletter en el pie de página.",
				});
			} else {
				this.setState({
					msgCompleteProfileSettings: null,
				});
			}
		} else {
			this.setState({
				msgCompleteProfileSettings: null,
			});
		}
		*/
  }

  profileComplete() {
    /*
		const { msgCompleteProfileProfesional } = this.state;
		const { msgCompleteProfilePersonal } = this.state;
		const { msgCompleteProfileSettings } = this.state;

		if (
			msgCompleteProfileProfesional === null &&
			msgCompleteProfilePersonal === null &&
			msgCompleteProfileSettings === null
		) {
			this.setState({
				msgCompleteProfile:
					"Su cuenta está activada y todos los campos necesarios completados.",
			});
		} else {
			this.setState({
				msgCompleteProfile: null,
			});
    }
    */
  }
  checkDateVotation = async () => {
    this.setState({
      fetchInformation: true,
    });
    await axios
      .post('/api/votes_check_dates')

      .then((res) => {
        this.setState({
          phase: res.data.records[1].phase,
          idVotation: res.data.records[0].id,
          fetchInformation: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchInformation: false,
        });
      });
  };
  getComitesUserActual = async () => {
    const { userInfo } = this.state;
    var userId = userInfo.id;
    let formData = new FormData();
    formData.append('id', userId);

    await axios
      .post('/api/users_comites_current_year_get_by_id', formData)
      .then((res) => {
        this.setState({
          comitesUserGet: res.data.records,
        });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    (async () => {
      await this.checkTokken();
      await this.getComitesAdmin();
      await this.getOrdersUser();
      await this.setMessageWarning();
      await this.profileComplete();
      await this.checkDateVotation();
      await this.checkDateVotationAssembly();
      await this.getComitesUserActual();
      await this.getNewSidebar();
      await this.setType();
      await this.getInformationComiteExecutive();
    })();
    let status = this.props.match.params.status;

    if (status !== '' && status === 'renovation') {
      this.setState({ key: 'afiliationData' });
    }
    //Check if key is sent to set it for the tabs
    if (this.props.history.location.state) {
      this.setState({
        key: this.props.history.location.state.key,
      });
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  getInformationComiteExecutive = async () => {
    await axios
      .get('/api/votes_ce_config_valid_dates_get')
      .then((res) => {
        this.setState({
          candidatureComiteExecutive: 1,
        });
      })
      .catch((error) => {
        this.setState({
          candidatureComiteExecutive: 0,
        });
      });
  };
  setType = async () => {
    if (+this.state.toColectivo !== 0) {
      if (+this.state.toColectivo === 46) {
        this.setState({
          toColectivoIdType: 14,
        });
        this.setState((prevState) => ({
          userInfo: {
            ...prevState.userInfo, //Mantener el resto de datos
            id_type: 14, // Modificar el dato que queremos
          },
        }));
      } else if (+this.state.toColectivo === 4) {
        this.setState({
          toColectivoIdType: 10,
        });
        this.setState((prevState) => ({
          userInfo: {
            ...prevState.userInfo, //Mantener el resto de datos
            id_type: 10, // Modificar el dato que queremos
          },
        }));
      } else if (+this.state.toColectivo === 48) {
        this.setState({
          toColectivoIdType: 12,
        });
        this.setState((prevState) => ({
          userInfo: {
            ...prevState.userInfo, //Mantener el resto de datos
            id_type: 12, // Modificar el dato que queremos
          },
        }));
      } else if (+this.state.toColectivo === 47) {
        this.setState({
          toColectivoIdType: 14,
        });
        this.setState((prevState) => ({
          userInfo: {
            ...prevState.userInfo, //Mantener el resto de datos
            id_type: 14, // Modificar el dato que queremos
          },
        }));
      }
    }
  };
  checkDateVotationAssembly = async () => {
    this.setState({
      fetchInformation: true,
    });
    await axios
      .post('/api/votes_a_check_dates')

      .then((res) => {
        this.setState({
          phaseAssembly: res.data.records[1].phase,
          idVotationAssembly: res.data.records[0].id,
          fetchInformation: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchInformation: false,
        });
      });
  };
  getNewSidebar = async () => {
    let formData = new FormData();
    this.setState({
      fetchingAdminOrder: true,
    });
    formData.append('id_categ', 21);
    await axios
      .post('/api/news_get_by_id_categ', formData)
      .then((res) => {
        this.setState({
          newSidebar: res.data.records,
          fetchingAdminOrder: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchingAdminOrder: false,
        });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.msgCompleteProfileProfesional !==
      this.state.msgCompleteProfileProfesional
    ) {
      (async () => {
        await this.profileComplete();
      })();
    }
    if (
      prevState.msgCompleteProfilePersonal !==
      this.state.msgCompleteProfilePersonal
    ) {
      (async () => {
        await this.profileComplete();
      })();
    }
    if (
      prevState.msgCompleteProfileSettings !==
      this.state.msgCompleteProfileSettings
    ) {
      (async () => {
        await this.profileComplete();
      })();
    }
  }
  handleSubmitPersonalInfo = (event) => {
    event.preventDefault();
    this.refs.btnPersonal.setAttribute('disabled', 'disabled');
    var nameError = 0;
    var bornCountryUserError = 0;
    var cityUserError = 0;
    var stateUserError = 0;
    var lastNameUser = 0;
    var phoneUser = 0;
    if (Cookies.get('fromAdmin') === '1') {
    } else {
      if (event.target.nameUser.value === '') {
        nameError = 'Necesita rellenar el nombre';
        //alert(nameError);
      } else if (event.target.bornCountryUser.value === '') {
        bornCountryUserError = 'Necesita rellenar el pais';
        //alert(bornCountryUserError);
      } else if (event.target.cityUser.value === '') {
        cityUserError = 'Necesita rellenar la ciudad';
        //alert(cityUserError);
      } else if (event.target.stateUser.value === '') {
        stateUserError = 'Necesita rellenar la Provincia';
        //alert(stateUserError);
      } else if (
        +this.state.userInfo.id_type === 7 &&
        event.target.lastNameUser.value === ''
      ) {
        lastNameUser = 'Necesita rellenar el Apellido';
        //alert(residencesUserError);
      } else if (event.target.mobileUser.value === '') {
        phoneUser = 'Necesita rellenar el Telefono';
        //alert(residencesUserError);
      }
    }

    if (
      nameError !== 0 ||
      bornCountryUserError !== 0 ||
      cityUserError !== 0 ||
      stateUserError !== 0 ||
      lastNameUser !== 0 ||
      phoneUser !== 0
    ) {
      alert('Hay campos obligatorios sin rellenar');
      this.refs.btnPersonal.removeAttribute('disabled');
    } else if (/[^0-9a-zA-Z]/.test(event.target.dniUser.value)) {
      alert(
        'El DNI introducido contiene caracteres no permitidos. Recuerde que el DNI solo puede contener números y letras.'
      );
      this.refs.btnPersonal.removeAttribute('disabled');
    } else {
      let formData = new FormData();

      if (+this.state.toColectivo !== 0) {
        formData.append('id_type', this.state.toColectivoIdType);
      } else {
        formData.append('id_type', this.state.userInfo.id_type);
      }
      formData.append('jwt', localStorage.getItem('access_token'));
      formData.append('name', event.target.nameUser.value);

      +this.state.userInfo.id_type === 7
        ? formData.append('lastname', event.target.lastNameUser.value)
        : formData.append('lastname', '');

      formData.append('nif', event.target.dniUser.value);
      formData.append('birthdate', event.target.yearBornUser.value);
      formData.append('country', event.target.bornCountryUser.value);
      formData.append('address_1', event.target.adressUser.value);
      formData.append('address_2', event.target.adressUser2.value);
      formData.append('city', event.target.cityUser.value);
      formData.append('region', event.target.stateUser.value);
      formData.append('country_residence', '');
      formData.append('phone', event.target.mobileUser.value);
      formData.append('landline', event.target.telUser.value);
      formData.append('postal_code', event.target.cpUser.value);

      axios
        .post('/api/users_update_personal_data', formData)
        .then((response) => {
          if (response.status === 200) {
            let jwt = response.data.jwt;

            localStorage.setItem('access_token', jwt);
            this.setState({ key: 'profesionalData', toColectivo: 0 });
          }
          alert('Se han modificado correctamente sus datos');
          (async () => {
            await this.checkTokken();
            await this.setMessageWarning();
            this.refs.btnPersonal.removeAttribute('disabled');
          })();
        })
        .catch((error) => {
          alert('No se ha podido modificar los datos');
          this.refs.btnPersonal.removeAttribute('disabled');
        });
    }
  };

  handleSubmitProfesionalInfo = (event) => {
    event.preventDefault();
    this.refs.btnProfesional.setAttribute('disabled', 'disabled');

    let formData = new FormData();

    var userId = this.state.userInfo.id;
    var allOk = 0;
    formData.append('id', userId);
    formData.append('name1', event.target.name1.value);
    formData.append('lastname1', event.target.lastname1.value);
    formData.append('email1', event.target.email1.value);
    formData.append('job1', event.target.job1.value);
    formData.append('address1', event.target.address1.value);
    formData.append('phone1', event.target.phone1.value);
    if (+event.target.sameContact.value === 0) {
      if (Cookies.get('fromAdmin') === '1') {
      } else {
        if (
          event.target.name1.value === '' ||
          event.target.lastname1.value === '' ||
          event.target.email1.value === '' ||
          event.target.job1.value === ''
        ) {
          alert('Faltan campos obligatorios');
          allOk = 1;
        }
      }

      formData.append('name2', event.target.name1.value);
      formData.append('lastname2', event.target.lastname1.value);
      formData.append('email2', event.target.email1.value);
      formData.append('job2', event.target.job1.value);
      formData.append('address2', event.target.address1.value);
      formData.append('phone2', event.target.phone1.value);
    } else if (+event.target.sameContact.value === 1) {
      if (Cookies.get('fromAdmin') === '1') {
      } else {
        if (
          event.target.name1.value === '' ||
          event.target.lastname1.value === '' ||
          event.target.email1.value === '' ||
          event.target.job1.value === '' ||
          event.target.name2.value === '' ||
          event.target.lastname2.value === '' ||
          event.target.email2.value === '' ||
          event.target.job2.value === ''
        ) {
          alert('Faltan campos obligatorios');
          allOk = 1;
        }
      }

      formData.append('name2', event.target.name2.value);
      formData.append('lastname2', event.target.lastname2.value);
      formData.append('email2', event.target.email2.value);
      formData.append('job2', event.target.job2.value);
      formData.append('address2', event.target.address2.value);
      formData.append('phone2', event.target.phone2.value);
    }

    if (allOk === 0) {
      formData.append('url', event.target.url.value);

      axios
        .post('/api/users_update_extra_data_backend', formData)
        .then((response) => {

          this.setState({ key: 'settingData' });

          alert('Se han modificado correctamente sus datos');
          this.setState({
            msgCompleteProfileProfesional: null,
          });
          this.refs.btnProfesional.removeAttribute('disabled');
        })
        .catch((error) => {
          alert('No se ha podido modificar los datos');
          this.refs.btnProfesional.removeAttribute('disabled');
        });
    }
    this.refs.btnProfesional.removeAttribute('disabled');
  };

  handleSettingsProfile = (event) => {
    event.preventDefault();
    this.refs.btnSettings.setAttribute('disabled', 'disabled');

    var joinNewsletter = 0;
    let formData = new FormData();
    if (event.target.joinNewsletter.value === '') {
      joinNewsletter = 'Campo newsletter';
      //alert(dniError);
    }
    if (joinNewsletter !== 0) {
      alert('Hay campos obligatorios sin rellenar');
      this.refs.btnSettings.removeAttribute('disabled');
    } else {
      formData.append('jwt', localStorage.getItem('access_token'));
      formData.append('newsletter', event.target.joinNewsletter.value);
      if (+event.target.joinNewsletter.value === 1) {
        let formData2 = new FormData();

        formData2.append('email', this.state.userInfo.email);

        axios
          .post('/api/addEmailToBoletin', formData2)
          .then((response) => {
            if (response.status === 200) {
            }
          })
          .catch((error) => {});
      } else if (+event.target.joinNewsletter.value === 0) {
        let formData2 = new FormData();
        formData2.append('email', this.state.userInfo.email);

        axios
          .post('/api/deleteEmailFromBoletin', formData2)
          .then((response) => {
            if (response.status === 200) {
            }
          })
          .catch((error) => {});
      }
      axios
        .post('/api/users_update_settings_data', formData)
        .then((response) => {
          if (response.status === 200) {
            let jwt = response.data.jwt;
            localStorage.setItem('access_token', jwt);
            this.setState({ key: 'afiliationData' });
            this.setState({
              messageCompletetoAfilitation: 1,
            });
          }
          alert('Se han modificado correctamente sus datos');
          (async () => {
            await this.checkTokken();
            await this.setMessageWarning();
            this.refs.btnSettings.removeAttribute('disabled');
          })();
        })
        .catch((error) => {
          alert('No se ha podido modificar los datos');
          this.refs.btnSettings.removeAttribute('disabled');
        });
    }
  };
  changeOptionGender = (event) => {
    this.setState((prevState) => ({
      userInfo: {
        ...prevState.userInfo, //Mantener el resto de datos
        gender: this.refs.genderUser.value, // Modificar el dato que queremos
      },
    }));
  };
  changeOptionLvlStudies = (event) => {
    this.setState((prevState) => ({
      userInfo: {
        ...prevState.userInfo, //Mantener el resto de datos
        education: this.refs.education.value, // Modificar el dato que queremos
      },
    }));
  };
  changeOptionActualInstitution = (event) => {
    this.setState((prevState) => ({
      userInfo: {
        ...prevState.userInfo, //Mantener el resto de datos
        org: this.refs.org.value, // Modificar el dato que queremos
      },
    }));
  };
  changeNewsletterJoin = (event) => {
    const valueforNJ = event.target.value;
    this.setState((prevState) => ({
      userInfo: {
        ...prevState.userInfo, //Mantener el resto de datos
        newsletter: valueforNJ, // Modificar el dato que queremos
      },
    }));
  };
  publicProfileRadio = (event) => {
    const valueforPP = event.target.value;
    this.setState((prevState) => ({
      userInfo: {
        ...prevState.userInfo, //Mantener el resto de datos
        type_status: valueforPP, // Modificar el dato que queremos
      },
    }));
  };
  sameContact = (event) => {
    const valueforsameContact = event.target.value;
    this.setState({ sameContact: +valueforsameContact });
  };
  handleChangeFocus(e) {
    if (e.target.value.length > 3) {
      document.getElementById('ibanEnd').focus();
    }
  }
  setshowLinkToPDF(e, comites, idPay, name) {
    let formData = new FormData();
    formData.append('id', idPay);

    if (name) {
      this.setState({
        nameInvoice: name,
      });
      if (comites.length > 0) {
        var numComitesPago = 0;
        comites.map((com, index) => {
          if (+com.type === 1) {
            numComitesPago++;
          }
          return null;
        });
        this.setState({
          numComitesPagoPDF: numComitesPago,
          showLinkToPDF: true,
        });
      } else {
        this.setState({
          numComitesPagoPDF: 0,
          showLinkToPDF: true,
        });
      }
    } else {
      axios
        .post('/api/payment_invoice_name_get', formData)
        .then((response) => {
          this.setState({
            nameInvoice: response.data.invoice_name,
          });
          if (comites.length > 0) {
            var numComitesPago = 0;
            comites.map((com, index) => {
              if (+com.type === 1) {
                numComitesPago++;
              }
              return null;
            });
            this.setState({
              numComitesPagoPDF: numComitesPago,
              showLinkToPDF: true,
            });
          } else {
            this.setState({
              numComitesPagoPDF: 0,
              showLinkToPDF: true,
            });
          }
        })
        .catch((error) => {
          if (comites.length > 0) {
            var numComitesPago = 0;
            comites.map((com, index) => {
              if (+com.type === 1) {
                numComitesPago++;
              }
              return null;
            });
            this.setState({
              numComitesPagoPDF: numComitesPago,
              showLinkToPDF: true,
            });
          } else {
            this.setState({
              numComitesPagoPDF: 0,
              showLinkToPDF: true,
            });
          }
        });
    }
  }
  setnotshowLinkToPDF(e) {
    this.setState({
      showLinkToPDF: false,
    });
  }
  setRenovationMesagge = () => {
    this.setState({
      msgRenovations:
        'Se ha abierto el plazo de renovación de AFILIACIÓN A FES. Si usted estuvo afiliado/a el año pasado puede entrar en la pestaña PEDIDOS y pulsando sobre el botón "Iniciar renovación" podrá renovar de nuevo su afiliación.',
    });
  };
  checkForRenew = (arrayofItems) => {
    this.setState({
      fetchingAdminOrder: true,
    });
    var temp_array = [];
    var temp_array_year = [];
    arrayofItems.forEach((item) => {
      if (+item.id_template !== 11 && +item.id_template !== 12) {
        var id = item.id;
        let formData = new FormData();
        formData.append('id', item.id);
        axios
          .post('/api/payment_renov_get', formData)
          .then((res) => {
            temp_array.push(id);
            temp_array_year.push(id);
            this.setRenovationMesagge();
          })
          .catch((error) => {
            if (error.response.data.message === 'Not today') {
              temp_array_year.push(id);
              return null;
            } else if (
              error.response.data.records[0].message ===
              'Date ok, but this order is already renovated'
            ) {
              temp_array_year.push(id);
            }
          });
      }
    });
    this.setState({
      renovableOrder: temp_array,
      orderForSidebar: temp_array_year,
      fetchingAdminOrder: false,
    });
  };
  checkIfRenovable = (id) => {
    if (this.state.renovableOrder.includes(id)) {
      return true;
    } else {
      return false;
    }
  };
  checkDatePayment = (dateToconvert) => {
    if (dateToconvert) {
      var date = dateToconvert;
      date = date.split(' ')[0];
      var yyyy = date.split('-')[0];
      var mmmm = date.split('-')[1];
      var dddd = date.split('-')[2];
      date = dddd + '-' + mmmm + '-' + yyyy;
      return date;
    }
  };
  freeSpotAvaliable = (numberofComites, paymentId, state) => {
    //var countFreeComites = 0;
    /*
		0-> Add free Comites
		1-> Add Pay Comites
		2-> Can't add comites
		*/

    if (+state !== 3) {
      return 2;
    } else if (
      this.state.comitesUserGet.length < 3 &&
      this.state.comitesUserGet.length >= 0
    ) {
      return 0;
    } else if (this.state.comitesUserGet.length >= 3) {
      return 1;
    } else {
      return 2;
    }
    /*
			
			for (var i = 0; i < numberofComites.length; i++) {
				if (numberofComites[i].type) {
					countFreeComites++;
				}
			}
			if (countFreeComites > 3) {
				return true;
			} else {
				return false;
			}
				}
			*/
  };
  messageByState = (
    idState,
    idOrder,
    idRenovation,
    idProduct,
    observations
  ) => {
    if (+idState === 6) {
      return (
        <div key={idOrder}>
          {this.checkIfRenovable(idRenovation) === true ? (
            <Link
              to={{
                pathname: `/producto/${idProduct}`,
                state: {
                  statusDoc: 1,
                  productID_confirmation: idProduct,
                  id_payment: idOrder,
                  renov_id: idRenovation,
                  renovationTime: true,
                },
              }}
            >
              Renovación pendiente de pago
            </Link>
          ) : (
            <Link
              to={{
                pathname: `/producto/${idProduct}`,
                state: {
                  statusDoc: 1,
                  productID_confirmation: idProduct,
                  id_payment: idOrder,
                  renov_id: idRenovation,
                },
              }}
            >
              Pedido pendiente
            </Link>
          )}
        </div>
      );
    } else if (+idState === 3) {
      return <div key={idOrder}>{observations}</div>;
    } else if (+idState === 7) {
      return (
        <div>
          {observations ? (
            <Fragment>
              {' '}
              {observations}
              <hr></hr>
              <Link
                to={{
                  pathname: `/producto/${idProduct}`,
                  state: {
                    statusDoc: 3,
                    productID_confirmation: idProduct,
                    id_payment: idOrder,
                    renov_id: idRenovation,
                  },
                }}
              >
                {' '}
                <div>Enviar Documentación</div>
              </Link>
            </Fragment>
          ) : (
            <Link
              to={{
                pathname: `/producto/${idProduct}`,
                state: {
                  statusDoc: 3,
                  productID_confirmation: idProduct,
                  id_payment: idOrder,
                },
              }}
            >
              {' '}
              <div>Enviar Documentación</div>
            </Link>
          )}
        </div>
      );
    } else if (+idState === 11) {
      return (
        <div>
          <Fragment>
            {' '}
            {observations}
            <Link
              to={{
                pathname: `/producto/${idProduct}`,
                state: {
                  productID_confirmation: idProduct,
                  id_payment: idOrder,
                  renovationTime: true,
                  renov_id: idRenovation,
                },
              }}
            >
              {' '}
              <div className="button">Iniciar proceso de renovación</div>
            </Link>
          </Fragment>
        </div>
      );
    } else if (+idState === 8) {
      return null;
    } else if (+idState === 12) {
      return (
        <div key={idOrder}>
          {this.checkIfRenovable(idRenovation) === true ? (
            <Link
              to={{
                pathname: `/producto/${idProduct}`,
                state: {
                  statusDoc: 1,
                  productID_confirmation: idProduct,
                  id_payment: idOrder,
                  renov_id: idRenovation,
                  renovationTime: true,
                },
              }}
            >
              Renovación pendiente de pago
            </Link>
          ) : (
            <Link
              to={{
                pathname: `/producto/${idProduct}`,
                state: {
                  statusDoc: 1,
                  productID_confirmation: idProduct,
                  id_payment: idOrder,
                  renov_id: idRenovation,
                  rePay: idOrder,
                },
              }}
            >
              Ir a Pago
            </Link>
          )}
        </div>
      );
    } else {
      return <td key={idOrder}>{observations}</td>;
    }
  };

  orderInformationInvoice = (
    idState,
    idOrder,
    idRenovation,
    idProduct,
    observations,
    record,
    userInfo,
    preName,
    ReceiptPdf
  ) => {
    if (+record.id_state === 6) {
      var showModal = showModal + record.id;
      return (
        <div>
          <p
            onClick={() => this.setState({ [showModal]: true })}
            className="linkStyle"
          >
            Información del pedido
          </p>
          <Modal
            show={this.state[showModal]}
            onHide={() =>
              this.setState({ [showModal]: false, showLinkToPDF: false })
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Resumen del pedido
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <tableBoostrapt striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Nombre del Producto</th>
                    <th>Fecha</th>
                    <th>Precio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Fragment>
                        {!preName
                          ? record.product_name +
                            '  (' +
                            record.payment_year +
                            ')'
                          : preName + ' (' + record.payment_year + ')'}
                      </Fragment>
                    </td>
                    <td>{record.initial_payment}</td>
                    <td>{+record.price / 100}€</td>
                  </tr>
                </tbody>
              </tableBoostrapt>
              <p className="infoResumeOrder">Nombre: {userInfo.name}</p>
              <p className="infoResumeOrder">Apellidos: {userInfo.lastname}</p>
              <p className="infoResumeOrder">
                Forma de pago: {record.payment_type_name}
              </p>
              <p className="infoResumeOrder">
                Estado del pago: {record.state_name}
              </p>
              <div>
                {this.state.showLinkToPDF ? (
                  <PDFDownloadLink
                    document={
                      <ReceiptPdf
                        payment_year={record.payment_year}
                        nombre={userInfo.name}
                        apellido={userInfo.lastname}
                        cantidad={+record.price / 100}
                        nameProduct={!preName ? record.product_name : preName}
                        formPayment={record.payment_type_name}
                        idPedido={record.id}
                        date={this.checkDatePayment(record.initial_payment)}
                        comites={record.comites}
                        tipoPago={record.payment_type}
                        idState={record.id_state}
                        idTemplate={record.id_template}
                        iva={record.iva}
                      />
                    }
                    fileName={'recibo_numero_pedido_' + record.id + '.pdf'}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <button className="button margin-top-20">
                          Cargando Recibo...
                        </button>
                      ) : (
                        <button
                          className="button margin-top-20"
                          onClick={() => this.setnotshowLinkToPDF()}
                        >
                          Descargar Recibo
                        </button>
                      )
                    }
                  </PDFDownloadLink>
                ) : (
                  <Button
                    className="button margin-top-20"
                    onClick={(e) =>
                      this.setshowLinkToPDF(
                        e,
                        record.comites,
                        record.id,
                        record.invoice_name
                      )
                    }
                  >
                    Preparar Recibo para descarga
                  </Button>
                )}
                {record.invoice ? (
                  <a
                    href={record.invoice}
                    target="_blank"
                    rel="noreferrer noopener"
                    download={'Factura - ' + record.id}
                  >
                    <Button className="button margin-top-20">
                      Obtener factura
                    </Button>
                  </a>
                ) : null}
              </div>
              <hr></hr>
              {+record.id_template === 5 ? (
                ''
              ) : (
                <span>
                  {record.comites.length > 0 ? (
                    <div>
                      {' '}
                      <p>Listado de comités Incluidos</p>{' '}
                      <tableBoostrapt
                        striped
                        bordered
                        hover
                        className="comiteTableProfile"
                      >
                        <thead>
                          <tr>
                            <th>Nombre</th>
                          </tr>
                          <tbody>
                            {record.comites.map((com, index) => {
                              return (
                                <tr key={index}>
                                  <td>{com.title}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </thead>
                      </tableBoostrapt>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </span>
              )}
            </Modal.Body>
          </Modal>
        </div>
      );
    } else if (+record.id_state === 3) {
      var showModal2 = showModal + record.id;
      return (
        <div key={record.id}>
          <p
            onClick={() => this.setState({ [showModal2]: true })}
            className="linkStyle"
          >
            Información del pedido
          </p>

          <Modal
            show={this.state[showModal2]}
            onHide={() =>
              this.setState({ [showModal2]: false, showLinkToPDF: false })
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Resumen del pedido
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <tableBoostrapt striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Nombre del Producto</th>
                    <th>Fecha</th>
                    <th>Precio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Fragment>
                        {!preName
                          ? record.product_name +
                            '  (' +
                            record.payment_year +
                            ')'
                          : preName + ' (' + record.payment_year + ')'}
                      </Fragment>
                    </td>
                    <td>{record.initial_payment}</td>
                    <td>{+record.price / 100}€</td>
                  </tr>
                </tbody>
              </tableBoostrapt>
              <p className="infoResumeOrder">Nombre: {userInfo.name}</p>
              <p className="infoResumeOrder">Apellidos: {userInfo.lastname}</p>
              <p className="infoResumeOrder">
                Forma de pago: {record.payment_type_name}
              </p>
              <p className="infoResumeOrder">
                Estado del pago: {record.state_name}
              </p>
              <div>
                {this.state.showLinkToPDF ? (
                  <PDFDownloadLink
                    document={
                      <ReceiptPdf
                        payment_year={record.payment_year}
                        nombre={userInfo.name}
                        apellido={userInfo.lastname}
                        cantidad={+record.price / 100}
                        nameProduct={!preName ? record.product_name : preName}
                        formPayment={record.payment_type_name}
                        idPedido={record.id}
                        date={this.checkDatePayment(record.initial_payment)}
                        comites={record.comites}
                        tipoPago={record.payment_type}
                        idState={record.id_state}
                        idTemplate={record.id_template}
                        iva={record.iva}
                      />
                    }
                    fileName={'recibo_numero_pedido_' + record.id + '.pdf'}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <button className="button margin-top-20">
                          Cargando Recibo...
                        </button>
                      ) : (
                        <button
                          className="button margin-top-20"
                          onClick={() => this.setnotshowLinkToPDF()}
                        >
                          Descargar Recibo
                        </button>
                      )
                    }
                  </PDFDownloadLink>
                ) : (
                  <Button
                    className="button margin-top-20"
                    onClick={(e) =>
                      this.setshowLinkToPDF(
                        e,
                        record.comites,
                        record.id,
                        record.invoice_name
                      )
                    }
                  >
                    Preparar Recibo para descarga
                  </Button>
                )}
                {record.invoice ? (
                  <a
                    href={record.invoice}
                    target="_blank"
                    rel="noreferrer noopener"
                    download={'Factura - ' + record.id}
                  >
                    <Button className="button margin-top-20">
                      Obtener factura
                    </Button>
                  </a>
                ) : null}
              </div>
              <hr></hr>
              {+record.id_template === 5 ? (
                ''
              ) : (
                <span>
                  {record.comites.length > 0 ? (
                    <div>
                      {' '}
                      <p>Listado de comités Incluidos</p>{' '}
                      <tableBoostrapt
                        striped
                        bordered
                        hover
                        className="comiteTableProfile"
                      >
                        <thead>
                          <tr>
                            <th>Nombre</th>
                          </tr>
                          <tbody>
                            {record.comites.map((com, index) => {
                              return (
                                <tr key={index}>
                                  <td>{com.title}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </thead>
                      </tableBoostrapt>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </span>
              )}
            </Modal.Body>
          </Modal>
        </div>
      );
    } else if (+record.id_state === 7) {
      var showModal4 = showModal + record.id;
      return (
        <div key={record.id}>
          <p
            onClick={() => this.setState({ [showModal4]: true })}
            className="linkStyle"
          >
            Información del pedido
          </p>
          <Modal
            show={this.state[showModal4]}
            onHide={() =>
              this.setState({ [showModal4]: false, showLinkToPDF: false })
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Resumen del pedido
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <tableBoostrapt striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Nombre del Producto</th>
                    <th>Fecha</th>
                    <th>Precio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Fragment>
                        {!preName
                          ? record.product_name +
                            '  (' +
                            record.payment_year +
                            ')'
                          : preName + ' (' + record.payment_year + ')'}
                      </Fragment>
                    </td>
                    <td>{record.initial_payment}</td>
                    <td>{+record.price / 100}€</td>
                  </tr>
                </tbody>
              </tableBoostrapt>
              <p className="infoResumeOrder">Nombre: {userInfo.name}</p>
              <p className="infoResumeOrder">Apellidos: {userInfo.lastname}</p>
              <p className="infoResumeOrder">
                Forma de pago: {record.payment_type_name}
              </p>
              <p className="infoResumeOrder">
                Estado del pago: {record.state_name}
              </p>
              <div>
                {this.state.showLinkToPDF ? (
                  <PDFDownloadLink
                    document={
                      <ReceiptPdf
                        payment_year={record.payment_year}
                        nombre={userInfo.name}
                        apellido={userInfo.lastname}
                        cantidad={+record.price / 100}
                        nameProduct={!preName ? record.product_name : preName}
                        formPayment={record.payment_type_name}
                        idPedido={record.id}
                        date={this.checkDatePayment(record.initial_payment)}
                        comites={record.comites}
                        tipoPago={record.payment_type}
                        idState={record.id_state}
                        idTemplate={record.id_template}
                        iva={record.iva}
                      />
                    }
                    fileName={'recibo_numero_pedido_' + record.id + '.pdf'}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <button className="button margin-top-20">
                          Cargando Recibo...
                        </button>
                      ) : (
                        <button
                          className="button margin-top-20"
                          onClick={() => this.setnotshowLinkToPDF()}
                        >
                          Descargar Recibo
                        </button>
                      )
                    }
                  </PDFDownloadLink>
                ) : (
                  <Button
                    className="button margin-top-20"
                    onClick={(e) =>
                      this.setshowLinkToPDF(
                        e,
                        record.comites,
                        record.id,
                        record.invoice_name
                      )
                    }
                  >
                    Preparar Recibo para descarga
                  </Button>
                )}
                {record.invoice ? (
                  <a
                    href={record.invoice}
                    target="_blank"
                    rel="noreferrer noopener"
                    download={'Factura - ' + record.id}
                  >
                    <Button className="button margin-top-20">
                      Obtener factura
                    </Button>
                  </a>
                ) : null}
              </div>
              <hr></hr>
              {+record.id_template === 5 ? (
                ''
              ) : (
                <span>
                  {record.comites.length > 0 ? (
                    <div>
                      {' '}
                      <p>Listado de comités Incluidos</p>{' '}
                      <tableBoostrapt
                        striped
                        bordered
                        hover
                        className="comiteTableProfile"
                      >
                        <thead>
                          <tr>
                            <th>Nombre</th>
                          </tr>
                          <tbody>
                            {record.comites.map((com, index) => {
                              return (
                                <tr key={index}>
                                  <td>{com.title}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </thead>
                      </tableBoostrapt>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </span>
              )}
            </Modal.Body>
          </Modal>
        </div>
      );
    } else if (+record.id_state === 11) {
      var showModal10 = showModal + record.id;
      return (
        <div key={record.id}>
          <p
            onClick={() => this.setState({ [showModal10]: true })}
            className="linkStyle"
          >
            Información del pedido
          </p>
          <Modal
            show={this.state[showModal10]}
            onHide={() =>
              this.setState({ [showModal10]: false, showLinkToPDF: false })
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Resumen del pedido
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <tableBoostrapt striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Nombre del Producto</th>
                    <th>Fecha</th>
                    <th>Precio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Fragment>
                        {!preName
                          ? record.product_name +
                            '  (' +
                            record.payment_year +
                            ')'
                          : preName + ' (' + record.payment_year + ')'}
                      </Fragment>
                    </td>
                    <td>{record.initial_payment}</td>
                    <td>{+record.price / 100}€</td>
                  </tr>
                </tbody>
              </tableBoostrapt>
              <p className="infoResumeOrder">Nombre: {userInfo.name}</p>
              <p className="infoResumeOrder">Apellidos: {userInfo.lastname}</p>
              <p className="infoResumeOrder">
                Forma de pago: {record.payment_type_name}
              </p>
              <p className="infoResumeOrder">
                Estado del pago: {record.state_name}
              </p>
              <div>
                {this.state.showLinkToPDF ? (
                  <PDFDownloadLink
                    document={
                      <ReceiptPdf
                        payment_year={record.payment_year}
                        nombre={userInfo.name}
                        apellido={userInfo.lastname}
                        cantidad={+record.price / 100}
                        nameProduct={!preName ? record.product_name : preName}
                        formPayment={record.payment_type_name}
                        idPedido={record.id}
                        date={this.checkDatePayment(record.initial_payment)}
                        comites={record.comites}
                        tipoPago={record.payment_type}
                        idState={record.id_state}
                        idTemplate={record.id_template}
                        iva={record.iva}
                      />
                    }
                    fileName={'recibo_numero_pedido_' + record.id + '.pdf'}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <button className="button margin-top-20">
                          Cargando Recibo...
                        </button>
                      ) : (
                        <button
                          className="button margin-top-20"
                          onClick={() => this.setnotshowLinkToPDF()}
                        >
                          Descargar Recibo
                        </button>
                      )
                    }
                  </PDFDownloadLink>
                ) : (
                  <Button
                    className="button margin-top-20"
                    onClick={(e) =>
                      this.setshowLinkToPDF(
                        e,
                        record.comites,
                        record.id,
                        record.invoice_name
                      )
                    }
                  >
                    Preparar Recibo para descarga
                  </Button>
                )}
                {record.invoice ? (
                  <a
                    href={record.invoice}
                    target="_blank"
                    rel="noreferrer noopener"
                    download={'Factura - ' + record.id}
                  >
                    <Button className="button margin-top-20">
                      Obtener factura
                    </Button>
                  </a>
                ) : null}
              </div>
              <hr></hr>
              {+record.id_template === 5 ? (
                ''
              ) : (
                <span>
                  {record.comites.length > 0 ? (
                    <div>
                      {' '}
                      <p>Listado de comités Incluidos</p>{' '}
                      <tableBoostrapt
                        striped
                        bordered
                        hover
                        className="comiteTableProfile"
                      >
                        <thead>
                          <tr>
                            <th>Nombre</th>
                          </tr>
                          <tbody>
                            {record.comites.map((com, index) => {
                              return (
                                <tr key={index}>
                                  <td>{com.title}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </thead>
                      </tableBoostrapt>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </span>
              )}
            </Modal.Body>
          </Modal>
        </div>
      );
    } else if (+record.id_state === 8) {
      return null;
    } else if (+record.id_state === 12) {
      var showModal12 = showModal + record.id;
      return (
        <div key={record.id}>
          <p
            onClick={() => this.setState({ [showModal12]: true })}
            className="linkStyle"
          >
            Información del pedido
          </p>
          <Modal
            show={this.state[showModal12]}
            onHide={() =>
              this.setState({ [showModal12]: false, showLinkToPDF: false })
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Resumen del pedido
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <tableBoostrapt striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Nombre del Producto</th>
                    <th>Fecha</th>
                    <th>Precio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Fragment>
                        {!preName
                          ? record.product_name +
                            '  (' +
                            record.payment_year +
                            ')'
                          : preName + ' (' + record.payment_year + ')'}
                      </Fragment>
                    </td>
                    <td>{record.initial_payment}</td>
                    <td>{+record.price / 100}€</td>
                  </tr>
                </tbody>
              </tableBoostrapt>
              <p className="infoResumeOrder">Nombre: {userInfo.name}</p>
              <p className="infoResumeOrder">Apellidos: {userInfo.lastname}</p>
              <p className="infoResumeOrder">
                Forma de pago: {record.payment_type_name}
              </p>
              <p className="infoResumeOrder">
                Estado del pago: {record.state_name}
              </p>
              <div>
                {this.state.showLinkToPDF ? (
                  <PDFDownloadLink
                    document={
                      <ReceiptPdf
                        payment_year={record.payment_year}
                        nombre={userInfo.name}
                        apellido={userInfo.lastname}
                        cantidad={+record.price / 100}
                        nameProduct={!preName ? record.product_name : preName}
                        formPayment={record.payment_type_name}
                        idPedido={record.id}
                        date={this.checkDatePayment(record.initial_payment)}
                        comites={record.comites}
                        tipoPago={record.payment_type}
                        idState={record.id_state}
                        idTemplate={record.id_template}
                        iva={record.iva}
                      />
                    }
                    fileName={'recibo_numero_pedido_' + record.id + '.pdf'}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <button className="button margin-top-20">
                          Cargando Recibo...
                        </button>
                      ) : (
                        <button
                          className="button margin-top-20"
                          onClick={() => this.setnotshowLinkToPDF()}
                        >
                          Descargar Recibo
                        </button>
                      )
                    }
                  </PDFDownloadLink>
                ) : (
                  <Button
                    className="button margin-top-20"
                    onClick={(e) =>
                      this.setshowLinkToPDF(
                        e,
                        record.comites,
                        record.id,
                        record.invoice_name
                      )
                    }
                  >
                    Preparar Recibo para descarga
                  </Button>
                )}
                {record.invoice ? (
                  <a
                    href={record.invoice}
                    target="_blank"
                    rel="noreferrer noopener"
                    download={'Factura - ' + record.id}
                  >
                    <Button className="button margin-top-20">
                      Obtener factura
                    </Button>
                  </a>
                ) : null}
              </div>
              <hr></hr>
              {+record.id_template === 5 ? (
                ''
              ) : (
                <span>
                  {record.comites.length > 0 ? (
                    <div>
                      {' '}
                      <p>Listado de comités Incluidos</p>{' '}
                      <tableBoostrapt
                        striped
                        bordered
                        hover
                        className="comiteTableProfile"
                      >
                        <thead>
                          <tr>
                            <th>Nombre</th>
                          </tr>
                          <tbody>
                            {record.comites.map((com, index) => {
                              return (
                                <tr key={index}>
                                  <td>{com.title}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </thead>
                      </tableBoostrapt>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </span>
              )}
            </Modal.Body>
          </Modal>
        </div>
      );
    } else {
      var showModal3 = showModal + record.id;
      return (
        <div key={record.id}>
          <p
            onClick={() => this.setState({ [showModal3]: true })}
            className="linkStyle"
          >
            Información del pedido
          </p>
          <Modal
            show={this.state[showModal3]}
            onHide={() =>
              this.setState({ [showModal3]: false, showLinkToPDF: false })
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Resumen del pedido
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <tableBoostrapt striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Nombre del Producto</th>
                    <th>Fecha</th>
                    <th>Precio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Fragment>
                        {!preName
                          ? record.product_name +
                            '  (' +
                            record.payment_year +
                            ')'
                          : preName + ' (' + record.payment_year + ')'}
                      </Fragment>
                    </td>
                    <td>{record.initial_payment}</td>
                    <td>{+record.price / 100}€</td>
                  </tr>
                </tbody>
              </tableBoostrapt>
              <p className="infoResumeOrder">Nombre: {userInfo.name}</p>
              <p className="infoResumeOrder">Apellidos: {userInfo.lastname}</p>
              <p className="infoResumeOrder">
                Forma de pago: {record.payment_type_name}
              </p>
              <p className="infoResumeOrder">
                Estado del pago: {record.state_name}
              </p>
              <div>
                {this.state.showLinkToPDF ? (
                  <PDFDownloadLink
                    document={
                      <ReceiptPdf
                        payment_year={record.payment_year}
                        nombre={userInfo.name}
                        apellido={userInfo.lastname}
                        cantidad={+record.price / 100}
                        nameProduct={!preName ? record.product_name : preName}
                        formPayment={record.payment_type_name}
                        idPedido={record.id}
                        date={this.checkDatePayment(record.initial_payment)}
                        comites={record.comites}
                        tipoPago={record.payment_type}
                        idState={record.id_state}
                        idTemplate={record.id_template}
                        iva={record.iva}
                      />
                    }
                    fileName={'recibo_numero_pedido_' + record.id + '.pdf'}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <button className="button margin-top-20">
                          Cargando Recibo...
                        </button>
                      ) : (
                        <button
                          className="button margin-top-20"
                          onClick={() => this.setnotshowLinkToPDF()}
                        >
                          Descargar Recibo
                        </button>
                      )
                    }
                  </PDFDownloadLink>
                ) : (
                  <Button
                    className="button margin-top-20"
                    onClick={(e) =>
                      this.setshowLinkToPDF(
                        e,
                        record.comites,
                        record.id,
                        record.invoice_name
                      )
                    }
                  >
                    Preparar Recibo para descarga
                  </Button>
                )}
                {record.invoice ? (
                  <a
                    href={record.invoice}
                    target="_blank"
                    rel="noreferrer noopener"
                    download={'Factura - ' + record.id}
                  >
                    <Button className="button margin-top-20">
                      Obtener factura
                    </Button>
                  </a>
                ) : null}
              </div>
              <hr></hr>
              {+record.id_template === 5 ? (
                ''
              ) : (
                <span>
                  {record.comites.length > 0 ? (
                    <div>
                      {' '}
                      <p>Listado de comités Incluidos</p>{' '}
                      <tableBoostrapt
                        striped
                        bordered
                        hover
                        className="comiteTableProfile"
                      >
                        <thead>
                          <tr>
                            <th>Nombre</th>
                          </tr>
                          <tbody>
                            {record.comites.map((com, index) => {
                              return (
                                <tr key={index}>
                                  <td>{com.title}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </thead>
                      </tableBoostrapt>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </span>
              )}
            </Modal.Body>
          </Modal>
        </div>
      );
    }
  };

  render() {
    const { userInfo } = this.state;
    const { statusPage } = this.state;
    const { userOrders } = this.state;
    const { msgCompleteProfilePersonal } = this.state;
    const { msgCompleteProfileProfesional } = this.state;
    const { msgCompleteProfileSettings } = this.state;
    const { msgCompleteProfile } = this.state;
    const { showLinkToPDF } = this.state;
    const { numComitesPagoPDF } = this.state;
    const { userComitesAdmin } = this.state;
    const { fetchingAdminOrder } = this.state;
    const { userExtra } = this.state;
    const changeToColectivotxt = (
      <p>
        {' '}
        Para cambiar su cuenta a colectivo haga click en guardar información.
      </p>
    );
    const styles = StyleSheet.create({
      page: { backgroundColor: 'white', padding: 10 },
      header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
      },
      text: {
        fontSize: 12,
        textAlign: 'left',
        color: 'black',
      },
      table: {
        fontSize: 12,
        display: 'table',
        width: '95%',
        borderStyle: 'solid',
      },
      tableRow: {
        fontSize: 12,
        margin: 'auto',
        flexDirection: 'row',
      },
      tableRowBlue: {
        fontSize: 12,
        margin: 'auto',
        flexDirection: 'row',
        backgroundColor: '#2a94ec',
      },
      tableCol: {
        fontSize: 12,
        width: '25%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
      },
      tableCol70: {
        fontSize: 12,
        width: '70%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000000',
      },
      tableCol67: {
        fontSize: 12,
        width: '67%',
      },
      tableCol15: {
        fontSize: 12,
        width: '15%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000000',
      },
      tableCol50: {
        width: '50%',
      },
      tableCol40: {
        fontSize: 12,
        width: '40%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000000',
      },
      tableCol40Transferencia: {
        width: '40%',
        color: 'black',
      },
      tableCol20Blue: {
        backgroundColor: '#2a94ec',
        color: '#FFFFFF',
        width: '20%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000000',
      },
      tableCol100Blue: {
        width: '100%',
        backgroundColor: '#2a94ec',
        color: '#FFFFFF',
      },
      tableCol10: {
        width: '10%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderColor: '#000000',
      },
      tableCol30: {
        width: '30%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000000',
      },
      tableCell: {
        fontSize: 12,
        margin: 'auto',
        marginTop: 5,
      },
      tableCol3: {
        fontSize: 12,
        width: '33.3333%',
        borderLeftWidth: 0,
        borderTopWidth: 0,
      },
      tableCol3blue: {
        fontSize: 12,
        width: '33.3333%',
        borderLeftWidth: 0,
        borderTopWidth: 0,
      },
      whiteSpace: {
        fontSize: 12,
        marginTop: 10,
        borderStyle: 'solid',
        marginBottom: 10,
      },
      bgBlue: {
        fontSize: 12,
        backgroundColor: '#2a94ec',
      },
      alignRight: {
        fontSize: 12,
        textAlign: 'right',
      },
      alignLeft: {
        fontSize: 12,
        textAlign: 'left',
      },
      image: {
        fontSize: 12,
        marginVertical: 15,
        marginHorizontal: 20,
        width: 300,
      },
      tableCell3Right: {
        fontSize: 12,
        textAlign: 'right',
        color: '#FFFFFF',
        padding: '3px',
      },
      tableCell3LeftWhite: {
        fontSize: 12,
        textAlign: 'left',
        color: '#FFFFFF',
        padding: '3px',
      },
      tableCell3RightBlack: {
        fontSize: 12,
        textAlign: 'right',
        color: '#0000000',
        padding: '3px',
      },
      tableCell3RightWhite: {
        fontSize: 12,
        textAlign: 'right',
        color: '#FFFFFF',
        padding: '3px',
      },
      tableCell3LeftBlack: {
        fontSize: 12,
        textAlign: 'left',
        color: '#000000',
        padding: '3px',
      },
      tableCell3CenterWhite: {
        fontSize: 12,
        textAlign: 'center',
        color: '#FFFFFF',
        padding: '3px',
      },
      tableCell3CenterBlack: {
        fontSize: 12,
        textAlign: 'center',
        color: '#000000',
        padding: '3px',
      },
      tableCell3RightBlackTransferencia: {
        fontSize: 12,
        textAlign: 'right',
        color: '#0000000',
        padding: '3px',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000000',
      },
      tableCell3LeftBlackTransferencia: {
        fontSize: 12,
        textAlign: 'left',
        color: '#0000000',
        padding: '3px',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000000',
      },
      footer: {
        flexDirection: 'row',
        position: 'absolute',
        bottom: 15,
        left: 0,
        right: 0,
        paddingLeft: 36,
      },
      footerColumn: {
        width: '100%',
      },
      textFooter: {
        textAlign: 'center',
        fontSize: 12,
      },
    });

    let ReceiptPdf;
    if (showLinkToPDF === true) {
      ReceiptPdf = (props) => (
        <Document>
          <Page size="A4" style={styles.page} wrap>
            <Image
              style={styles.image}
              src={global.urlHome + 'images/fes_logo.jpg'}
            />
            <View style={styles.table}>
              <View style={styles.tableRowBlue}>
                <View style={styles.tableCol3blue}>
                  {this.state.nameInvoice ? (
                    <Text style={styles.tableCell3LeftWhite}>
                      FACTURA {this.state.nameInvoice}
                    </Text>
                  ) : (
                    <Text style={styles.tableCell3LeftWhite}>
                      FACTURA FES/{props.idPedido}
                    </Text>
                  )}
                </View>
                <View style={styles.tableCol3blue}>
                  <Text style={styles.tableCell3LeftWhite}></Text>
                </View>
                <View style={styles.tableCol3blue}>
                  <Text style={styles.tableCell3RightWhite}>{props.date}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell3LeftBlack}>
                    CIF: {userInfo.nif}
                  </Text>
                </View>
                <View style={styles.tableCol67}>
                  <Text style={styles.tableCell3LeftBlack}>{props.nombre}</Text>
                  <Text style={styles.tableCell3LeftBlack}>
                    {props.apellido}
                  </Text>
                  <Text style={styles.tableCell3LeftBlack}>
                    {userInfo.address_1}
                  </Text>
                  <Text style={styles.tableCell3LeftBlack}>
                    {userInfo.address_2} {userInfo.postal_code}
                  </Text>
                  <Text style={styles.tableCell3LeftBlack}>
                    {userInfo.city}
                  </Text>
                  <Text style={styles.tableCell3LeftBlack}>
                    {userInfo.region} {userInfo.country}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRowBlue}>
                <View style={styles.tableCol40}>
                  <Text style={styles.tableCell3LeftWhite}>
                    CONCEPTO PEDIDO Nº{props.idPedido}
                  </Text>
                </View>
                <View style={styles.tableCol15}>
                  <Text style={styles.tableCell3CenterWhite}>Cantidad</Text>
                </View>
                <View style={styles.tableCol15}>
                  <Text style={styles.tableCell3CenterWhite}>B.I.</Text>
                </View>
                <View style={styles.tableCol15}>
                  <Text style={styles.tableCell3CenterWhite}>IVA</Text>
                </View>
                <View style={styles.tableCol15}>
                  <Text style={styles.tableCell3CenterWhite}>Total</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol40}>
                  <Text style={styles.tableCell3LeftBlack}>
                    {props.nameProduct + ' '}{' '}
                    {+props.payment_year === 0 ? '' : props.payment_year}
                  </Text>
                </View>
                <View style={styles.tableCol15}>
                  <Text style={styles.tableCell3CenterBlack}>1</Text>
                </View>
                <View style={styles.tableCol15}>
                  <Text style={styles.tableCell3CenterBlack}>
                    {+props.cantidad === 0
                      ? '0€'
                      : props.cantidad -
                        numComitesPagoPDF * global.priceSingleComite -
                        (props.iva
                          ? (props.cantidad * (props.iva / 100)).toFixed(2)
                          : 0) +
                        '€'}
                  </Text>
                </View>
                <View style={styles.tableCol15}>
                  <Text style={styles.tableCell3CenterBlack}>
                    {!props.iva || +props.iva === 0
                      ? 'EXENTO'
                      : props.iva + '%'}
                  </Text>
                </View>
                <View style={styles.tableCol15}>
                  <Text style={styles.tableCell3CenterBlack}>
                    {+props.cantidad === 0
                      ? '0€'
                      : props.cantidad -
                        numComitesPagoPDF * global.priceSingleComite -
                        (props.iva
                          ? (props.cantidad * (props.iva / 100)).toFixed(2)
                          : 0) +
                        '€'}
                  </Text>
                </View>
              </View>
              {+numComitesPagoPDF === 0 ? null : (
                <View style={styles.tableRow}>
                  <View style={styles.tableCol40}>
                    <Text style={styles.tableCell3LeftBlack}>
                      Comité/Grupo de trabajo extra
                    </Text>
                  </View>
                  <View style={styles.tableCol15}>
                    <Text style={styles.tableCell3CenterBlack}>
                      {numComitesPagoPDF}
                    </Text>
                  </View>
                  <View style={styles.tableCol15}>
                    <Text style={styles.tableCell3CenterBlack}>
                      {global.priceSingleComite}€
                    </Text>
                  </View>
                  <View style={styles.tableCol15}>
                    <Text style={styles.tableCell3CenterBlack}>EXENTO</Text>
                  </View>
                  <View style={styles.tableCol15}>
                    <Text style={styles.tableCell3CenterBlack}>
                      {numComitesPagoPDF * global.priceSingleComite}€
                    </Text>
                  </View>
                </View>
              )}
            </View>
            <Text style={styles.whiteSpace}></Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol50}>
                  <Text style={styles.tableCell3LeftWhite}></Text>
                </View>
                <View style={styles.tableCol20Blue}>
                  <Text style={styles.tableCell3RightWhite}>Subtotal</Text>
                </View>
                <View style={styles.tableCol30}>
                  <Text style={styles.tableCell3LeftBlack}>
                    {props.cantidad -
                      (props.iva
                        ? (props.cantidad * (props.iva / 100)).toFixed(2)
                        : 0)}
                    €
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol50}>
                  <Text style={styles.tableCell3LeftWhite}></Text>
                </View>
                <View style={styles.tableCol20Blue}>
                  <Text style={styles.tableCell3RightWhite}>
                    {props.iva ? 'IVA' : 'Exenta IVA(s/art.20.1.12)'}
                  </Text>
                </View>
                <View style={styles.tableCol30}>
                  <Text style={styles.tableCell3LeftBlack}>
                    {' '}
                    {props.iva
                      ? (props.cantidad * (props.iva / 100)).toFixed(2) + '€'
                      : '0,00€'}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol50}>
                  <Text style={styles.tableCell3LeftWhite}></Text>
                </View>
                <View style={styles.tableCol20Blue}>
                  <Text style={styles.tableCell3RightWhite}>Total</Text>
                </View>
                <View style={styles.tableCol30}>
                  <Text style={styles.tableCell3LeftBlack}>
                    {props.cantidad}€
                  </Text>
                </View>
              </View>
            </View>
            <Text style={styles.whiteSpace}></Text>
            {+props.tipoPago === 2 ? (
              +props.idState !== 3 ? (
                <View>
                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol100Blue}>
                        <Text style={styles.tableCell3CenterWhite}>
                          Factura pendiente de pago por Transferencia
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                      <View style={styles.tableCol40Transferencia}>
                        <Text style={styles.tableCell3RightBlackTransferencia}>
                          Beneficiario
                        </Text>
                      </View>
                      <View style={styles.tableCol40Transferencia}>
                        <Text style={styles.tableCell3LeftBlackTransferencia}>
                          FES
                        </Text>
                      </View>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                    </View>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                      <View style={styles.tableCol40Transferencia}>
                        <Text style={styles.tableCell3RightBlackTransferencia}>
                          Concepto
                        </Text>
                      </View>
                      <View style={styles.tableCol40Transferencia}>
                        <Text style={styles.tableCell3LeftBlackTransferencia}>
                          FES/{props.idPedido}
                        </Text>
                      </View>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                    </View>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                      <View style={styles.tableCol40Transferencia}>
                        <Text style={styles.tableCell3RightBlackTransferencia}>
                          Banco
                        </Text>
                      </View>
                      <View style={styles.tableCol40Transferencia}>
                        <Text style={styles.tableCell3LeftBlackTransferencia}>
                          CAIXABANK
                        </Text>
                      </View>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                    </View>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                      <View style={styles.tableCol40Transferencia}>
                        <Text style={styles.tableCell3RightBlackTransferencia}>
                          IBAN
                        </Text>
                      </View>
                      <View style={styles.tableCol40Transferencia}>
                        <Text style={styles.tableCell3LeftBlackTransferencia}>
                          ES81 2100 3100 5013 0019 1562
                        </Text>
                      </View>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                    </View>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                      <View style={styles.tableCol40Transferencia}>
                        <Text style={styles.tableCell3RightBlackTransferencia}>
                          SWIFT/BIC
                        </Text>
                      </View>
                      <View style={[styles.tableCol40Transferencia]}>
                        <Text style={styles.tableCell3LeftBlackTransferencia}>
                          CAIXESBBXXX
                        </Text>
                      </View>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                    </View>
                  </View>
                </View>
              ) : null
            ) : null}

            <View style={styles.footer} fixed>
              <View style={styles.footerColumn}>
                <Text style={styles.textFooter}>
                  Federación Española de Sociología · G-28918514 · Montalbán, 8
                  - 28014 Madrid
                </Text>
                <Text style={styles.textFooter}>
                  Tel./Fax: 91 5232741 · E-mail: info@fes-sociologia.com{' '}
                </Text>
              </View>
            </View>
          </Page>
        </Document>
      );
    }

    if (statusPage === 1) {
      localStorage.removeItem('access_token');
      return <Redirect to="/login" />;
    }

    const personalUserInfo = (
      <Form onSubmit={this.handleSubmitPersonalInfo}>
        <Row>
          <Col lg={6} sm={12}>
            <Form.Group controlId="nameUser">
              {+userInfo.id_type === 6 || +userInfo.id_type === 12 ? (
                <Form.Label>Nombre de la Entidad*</Form.Label>
              ) : +userInfo.id_type === 7 || +userInfo.id_type === 14 ? (
                <Form.Label>Nombre de la Universidad*</Form.Label>
              ) : (
                <Form.Label>Nombre de la Asociación*</Form.Label>
              )}
              <Form.Control
                type="text"
                placeholder="Nombre"
                name="nameUser"
                required
                defaultValue={userInfo.name}
              />
            </Form.Group>
          </Col>
          {+userInfo.id_type === 7 || +userInfo.id_type === 14 ? (
            <Col lg={6} sm={12}>
              <Form.Group controlId="lastNameUser">
                <Form.Label>Nombre de la facultad y departamento*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre de la facultad y departamento"
                  name="lastNameUser"
                  required
                  defaultValue={userInfo.lastname}
                />
              </Form.Group>
            </Col>
          ) : null}

          <Col lg={6} sm={12}>
            <Form.Group controlId="dniUser" className="fieldUnderText">
              <Form.Label>CIF*</Form.Label>
              <Form.Control
                type="text"
                name="dniUser"
                placeholder="CIF"
                required
                defaultValue={userInfo.nif}
              />
            </Form.Group>
            <p className="smallTextExplanation">
              El CIF debe estar formado solo por letras y números. No puede
              contener caractéres especiales o espacios en blanco.
            </p>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="emailUser">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                readOnly={true}
                type="email"
                name="emailUser"
                placeholder="email"
                defaultValue={userInfo.email}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="yearBornUser">
              <Form.Label>Año de constitución</Form.Label>
              <Form.Control
                type="date"
                placeholder="Año de constitución"
                name="yearBornUser"
                defaultValue={userInfo.birthdate}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="phoneMobile" className="fieldUnderText">
              <Form.Label>Teléfono 1*</Form.Label>
              <Form.Control
                type="text"
                name="mobileUser"
                placeholder="Teléfono 1"
                required
                defaultValue={userInfo.phone}
              />
            </Form.Group>
          </Col>

          <Col lg={6} sm={12}>
            <Form.Group controlId="phoneHomeUser">
              <Form.Label>Teléfono 2</Form.Label>
              <Form.Control
                type="tel"
                name="telUser"
                placeholder="Teléfono 2"
                defaultValue={userInfo.landline}
              />
            </Form.Group>
          </Col>

          <Col lg={6} sm={12}>
            <Form.Group controlId="adressUser">
              <Form.Label>Dirección*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Dirección"
                name="adressUser"
                required
                defaultValue={userInfo.address_1}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="adressUser2">
              <Form.Label>Dirección (campo 2)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Dirección"
                name="adressUser2"
                defaultValue={userInfo.address_2}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="cpUser">
              <Form.Label>Código Postal*</Form.Label>
              <Form.Control
                type="text"
                name="cpUser"
                placeholder="Código Postal"
                required
                defaultValue={userInfo.postal_code}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="cityUser">
              <Form.Label>Localidad*</Form.Label>
              <Form.Control
                type="text"
                name="cityUser"
                placeholder="Localidad"
                required
                defaultValue={userInfo.city}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="stateUser">
              <Form.Label>Provincia*</Form.Label>
              <Form.Control
                type="text"
                name="stateUser"
                placeholder="Provincia"
                required
                defaultValue={userInfo.region}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="bornCountryUser">
              <Form.Label>Pais*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Pais"
                name="bornCountryUser"
                required
                defaultValue={userInfo.country}
              />
            </Form.Group>
          </Col>

          <Col sm={12} xs={12}>
            <Button ref="btnPersonal" variant="primary" type="submit">
              Guardar información
            </Button>
          </Col>
        </Row>
      </Form>
    );

    const settingsUserInfo = (
      <Form onSubmit={this.handleSettingsProfile}>
        <Row>
          <Col lg={6} sm={12}>
            <Form.Group controlId="joinNewsletter">
              <Form.Label as="legend">
                1*¿Quieres recibir la newsletter de FES?
              </Form.Label>
              <Form.Check
                type="radio"
                label="Si"
                value="1"
                name="joinNewsletter"
                ref="joinNewsletter"
                id="newsletterConfirmationYes"
                checked={userInfo.newsletter === '1'}
                onChange={this.changeNewsletterJoin}
              />
              <Form.Check
                type="radio"
                label="No"
                value="0"
                name="joinNewsletter"
                ref="joinNewsletter"
                id="newsletterConfirmationNo"
                checked={userInfo.newsletter === '0'}
                onChange={this.changeNewsletterJoin}
              />
            </Form.Group>
          </Col>
          <Col lg={12}>
            <p>
              Tenga en cuenta que el boletín se recibirá en la dirección de
              correo general de la asociación/entidad registrada en este
              formulario. Para poder recibir el boletín en otras direcciones de
              correo (presidencia, dirección, otras personas de contacto…) es
              necesario que se registren personalmente a través del formulario
              de suscripción al boletín situado en la página principal de la web
              de FES.
            </p>
            <p>
              Si desea darse de baja de su afiliación a FES puede escribir a la
              secretaría (
              <a href="mailto:info@fes-sociologia.com">
                info@fes-sociologia.com
              </a>
              ).
            </p>
            <hr></hr>
          </Col>

          <Col lg={12}>
            <p className="small">
              1* -> Quiero recibir el boletín periódico con contenidos de
              actualidad, noticias de FES, sus asociados y otras entidades de
              interés.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Button ref="btnSettings" variant="primary" type="submit">
              Guardar información
            </Button>
          </Col>
        </Row>
      </Form>
    );

    const profesionalUserInfo = (
      <Form onSubmit={this.handleSubmitProfesionalInfo}>
        <Row>
          <Col>
            <p></p>
          </Col>
          <Col lg={12} sm={12}>
            <h5>Contacto institucional</h5>
            <p>(Presidente/a o Director/a de la Asociación o entidad):</p>
          </Col>

          <Col lg={6} sm={12}>
            <Form.Group controlId="name1" className="fieldUnderText">
              <Form.Label>Nombre*</Form.Label>
              <Form.Control
                type="text"
                name="name1"
                placeholder="nombre"
                defaultValue={userExtra.name1}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="lastname1" className="fieldUnderText">
              <Form.Label>Apellidos*</Form.Label>
              <Form.Control
                type="text"
                name="lastname1"
                placeholder="Apellidos"
                defaultValue={userExtra.lastname1}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="job1" className="fieldUnderText">
              <Form.Label>Cargo*</Form.Label>
              <Form.Control
                type="text"
                name="job1"
                placeholder="Cargo"
                defaultValue={userExtra.job1}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="email1" className="fieldUnderText">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                type="text"
                name="email1"
                placeholder="Email"
                defaultValue={userExtra.email1}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="address1" className="fieldUnderText">
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                type="text"
                name="address1"
                placeholder="Dirección"
                defaultValue={userExtra.address1}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="phone1" className="fieldUnderText">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="text"
                name="phone1"
                placeholder="Teléfono"
                defaultValue={userExtra.phone1}
              />
            </Form.Group>
          </Col>
          <Col lg={12} sm={12}>
            <hr></hr>
          </Col>
          <Col lg={12} sm={12}>
            <h5>Contacto administrativo</h5>
            <p>(secretario, tesorero…)</p>
          </Col>
          <Col lg={12} sm={12}>
            <Form.Group controlId="publicProfile">
              <Form.Label as="legend">
                ¿Es el contacto administrativo el mismo que el contacto
                institucional?
              </Form.Label>
              <Form.Check
                type="radio"
                label="Si"
                value="0"
                name="sameContact"
                id="sameContact0"
                ref="sameContact"
                onChange={this.sameContact}
                checked={this.state.sameContact === 0}
              />
              <Form.Check
                type="radio"
                label="No"
                value="1"
                name="sameContact"
                id="sameContact1"
                ref="sameContact"
                onChange={this.sameContact}
                checked={this.state.sameContact === 1}
              />
            </Form.Group>
          </Col>
          {+this.state.sameContact === 0 ? null : (
            <Row>
              <Col lg={6} sm={12}>
                <Form.Group controlId="name2" className="fieldUnderText">
                  <Form.Label>Nombre*</Form.Label>
                  <Form.Control
                    type="text"
                    name="name2"
                    placeholder="nombre"
                    defaultValue={userExtra.name2}
                  />
                </Form.Group>
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group controlId="lastname2" className="fieldUnderText">
                  <Form.Label>Apellidos*</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname2"
                    placeholder="Apellidos"
                    defaultValue={userExtra.lastname2}
                  />
                </Form.Group>
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group controlId="job2" className="fieldUnderText">
                  <Form.Label>Cargo*</Form.Label>
                  <Form.Control
                    type="text"
                    name="job2"
                    placeholder="Cargo"
                    defaultValue={userExtra.job2}
                  />
                </Form.Group>
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group controlId="email2" className="fieldUnderText">
                  <Form.Label>Email*</Form.Label>
                  <Form.Control
                    type="text"
                    name="email2"
                    placeholder="Email"
                    defaultValue={userExtra.email2}
                  />
                </Form.Group>
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group controlId="address2" className="fieldUnderText">
                  <Form.Label>Dirección</Form.Label>
                  <Form.Control
                    type="text"
                    name="address2"
                    placeholder="Dirección"
                    defaultValue={userExtra.address2}
                  />
                </Form.Group>
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group controlId="phone2" className="fieldUnderText">
                  <Form.Label>Teléfono</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone2"
                    placeholder="Teléfono"
                    defaultValue={userExtra.phone2}
                  />
                </Form.Group>
              </Col>
              <Col lg={12} sm={12}>
                <hr></hr>
              </Col>
            </Row>
          )}
          <Col lg={6} sm={12}>
            <Form.Group controlId="url" className="fieldUnderText">
              <Form.Label>Web</Form.Label>
              <Form.Control
                type="text"
                name="url"
                placeholder="Web"
                defaultValue={userExtra.url}
              />
            </Form.Group>
          </Col>
          <Col lg={12} sm={12}>
            <hr></hr>
          </Col>
          <Col sm={12} xs={12}>
            <Button ref="btnProfesional" variant="primary" type="submit">
              Guardar información
            </Button>
          </Col>
        </Row>
      </Form>
    );

    const modal = (
      <div>
        <Modal
          show={this.state.showModal2}
          onHide={() => this.setState({ showModal2: false })}
        >
          modal two content..
        </Modal>
      </div>
    );

    let userbuttonafiliation;
    if (+userInfo.id_type === 4) {
      userbuttonafiliation = (
        <a href="/producto/53">
          <button className="button buttonSidebar width-100">
            Renovación de afiliación Socio/a Miembro colectivo Tipo A
            (Asociaciones territoriales mayor 100 socios)
          </button>
        </a>
      );
    } else if (+userInfo.id_type === 5) {
      userbuttonafiliation = (
        <a href="/producto/54">
          <button className="button buttonSidebar width-100">
            Renovación de afiliación Socio/a Miembro colectivo Tipo A
            (Asociaciones territoriales menor 100 socios)
          </button>
        </a>
      );
    } else if (+userInfo.id_type === 7) {
      userbuttonafiliation = (
        <a href="/producto/55">
          <button className="button buttonSidebar width-100">
            Renovación de afiliación Socio/a como Miembro del Consejo Académico
            (miembros colectivos tipo B)
          </button>
        </a>
      );
    } else if (+userInfo.id_type === 6) {
      userbuttonafiliation = (
        <a href="/producto/56">
          <button className="button buttonSidebar width-100">
            Renovación de afiliación Socio/a miembro del Consejo Profesional
            (miembros colectivos tipo C)
          </button>
        </a>
      );
    }

    const sidebar = !fetchingAdminOrder ? (
      <div>
        <SidebarProfile
          userInfo={userInfo}
          userOrders={userOrders}
          userComitesAdmin={userComitesAdmin}
          votationActive={this.state.phase}
          votationActiveAssembly={this.state.phaseAssembly}
          comitesUserGet={this.state.comitesUserGet}
          newSidebar={this.state.newSidebar}
          orderForSidebar={this.state.orderForSidebar}
          renovableOrder={this.state.renovableOrder}
          candidatureComiteExecutive={this.state.candidatureComiteExecutive}
          isColective="0"
          {...this.state}
        ></SidebarProfile>
        {Cookies.get('fromAdmin') === '1' ? userbuttonafiliation : null}
      </div>
    ) : (
      <p>Cargando</p>
    );
    const renovationButton = userOrders.map((UO, index) => {
      if (+UO.id_state === 3) {
        return this.checkIfRenovable(UO.id) === true ? (
          +UO.id_template === 6 ? (
            <div key={index}>
              <p>
                Puede iniciar el proceso de renovación haciendo click en el
                siguiente botón
              </p>
              <Link
                to={{
                  pathname: `/producto/3`,
                  state: {
                    productID_confirmation: UO.id_product,
                    renov_id: UO.id,
                    renovationTime: true,
                    id_product_renov: UO.id_product,
                    oldPayment: UO.payment_type,
                  },
                }}
              >
                {' '}
                <div className="button">Iniciar proceso de renovación</div>
              </Link>
              <Divider dashed></Divider>
            </div>
          ) : (
            <div key={index}>
              <p>
                Puede iniciar el proceso de renovación haciendo click en el
                siguiente botón
              </p>
              <Link
                to={{
                  pathname: `/producto/${UO.id_product}`,
                  state: {
                    productID_confirmation: UO.id_product,
                    renov_id: UO.id,
                    renovationTime: true,
                    oldPayment: UO.payment_type,
                  },
                }}
              >
                {' '}
                <div className="button">Iniciar proceso de renovación</div>
              </Link>
              <Divider dashed></Divider>
            </div>
          )
        ) : null;
      } else {
        return null;
      }
    });

    const { islogged } = this.state;
    if (islogged === 0) {
      //return <Redirect to="/" />;
    } else {
    }

    const title = (
      <TopImageTitle
        title="CUENTA DE COLECTIVO"
        subtitle="Toda tu información"
        bread01="Perfil"
      ></TopImageTitle>
    );
    const columnsUserOrders = [
      {
        title: 'Nº Pedido',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Fecha',
        dataIndex: 'initial_payment',
        key: 'initial_payment',
        render: (text, record) => this.checkDatePayment(text),
      },
      {
        title: 'Productos',
        dataIndex: 'product_name',
        key: 'product_name',
        render: (text, record) => (
          <div>
            <p>{text + ' (' + record.payment_year + ') '}</p>
            <p>
              {' '}
              {record.show_add_comites === true && this.state.allowAddComites ? (
                this.freeSpotAvaliable(
                  record.comites,
                  record.id,
                  record.id_state
                ) === 1 ? (
                  <Link
                    to={{
                      pathname: `/producto/79`,
                      state: {
                        statusDoc: 1,
                        id_payment: record.id,
                        typeOfAdd: 1,
                        userID: this.state.userInfo.id,
                      },
                    }}
                  >
                    Añadir comités
                  </Link>
                ) : this.freeSpotAvaliable(
                    record.comites,
                    record.id,
                    record.id_state
                  ) === 0 ? (
                  <Link
                    to={{
                      pathname: `/producto/79`,
                      state: {
                        statusDoc: 1,
                        id_payment: record.id,
                        typeOfAdd: 0,
                        userID: this.state.userInfo.id,
                      },
                    }}
                  >
                    Añadir comités Gratuitos
                  </Link>
                ) : (
                  ''
                )
              ) : (
                <span></span>
              )}
            </p>
          </div>
        ),
      },
      {
        title: 'Estado',
        dataIndex: 'state_name',
        key: 'state_name',
      },
      {
        title: 'Mensaje',
        dataIndex: '',
        key: 'y',
        render: (text, record) => (
          <div>
            {this.messageByState(
              record.id_state,
              record.id,
              record.renov_id,
              record.id_product,
              record.observations
            )}
          </div>
        ),
      },
      {
        title: 'Información de pedido / Factura',
        dataIndex: '',
        key: 'y',
        render: (text, record) => (
          <div>
            {this.orderInformationInvoice(
              record.id_state,
              record.id,
              record.renov,
              record.id_product,
              record.observations,
              record,
              this.state.userInfo,
              record.id_template === '6'
                ? 'Socio/a  de Territorial inscrito/a a comités'
                : '',
              ReceiptPdf
            )}
          </div>
        ),
      },
    ];
    return (
      <div>
        <SEO title="Perfil de Usuario" key={'SEO-user'}></SEO>
        <hr className="whiteSpace"></hr>
        {title}
        <section>
          <Container>
            <Row>
              <Col lg={3} sm={12}>
                {modal}
                {sidebar}
              </Col>

              <Col lg={9} sm={12}>
                {msgCompleteProfilePersonal ? (
                  <div className="alert alert-warning" role="alert">
                    <p>{msgCompleteProfilePersonal}</p>
                  </div>
                ) : msgCompleteProfileProfesional ? (
                  <div className="alert alert-warning" role="alert">
                    <p>{msgCompleteProfileProfesional}</p>
                  </div>
                ) : msgCompleteProfileSettings ? (
                  <div className="alert alert-warning" role="alert">
                    <p>{msgCompleteProfileSettings}</p>
                  </div>
                ) : null}

                {this.state.toColectivo !== 0 ? (
                  <div className="alert alert-warning" role="alert">
                    {changeToColectivotxt}
                  </div>
                ) : (
                  <div></div>
                )}
                {msgCompleteProfile ? (
                  <div className="alert alert-success" role="alert">
                    <p>{msgCompleteProfile}</p>
                  </div>
                ) : null}
                {this.state.msgRenovations ? (
                  <div className="alert alert-warning" role="alert">
                    <p>{this.state.msgRenovations}</p>
                  </div>
                ) : null}

                {this.state.messageCompletetoAfilitation === 1 ? (
                  <div className="alert alert-warning" role="alert">
                    <p>
                      Ha completado todos los datos requeridos. Puede proceder
                      con su afiliación a través del siguiente enlace:
                    </p>
                    <a href="/afiliate" className="button">
                      Afiliación
                    </a>
                  </div>
                ) : (
                  ''
                )}
                <Tabs
                  className="tabsProfile"
                  defaultActiveKey="personalData"
                  activeKey={this.state.key}
                  onSelect={this.handleSelect}
                >
                  <Tab eventKey="personalData" title="Datos del Colectivo">
                    <div className="profileUserInfo"> {personalUserInfo}</div>{' '}
                  </Tab>
                  <Tab eventKey="profesionalData" title="Datos de Contacto">
                    {profesionalUserInfo}
                  </Tab>
                  <Tab eventKey="settingData" title="Comunicaciones">
                    {settingsUserInfo}
                  </Tab>
                  <Tab eventKey="afiliationData" title="Pedidos">
                    {renovationButton}
                    <div hidden>
                      {' '}
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th>Nº Pedido</th>
                            <th>Fecha</th>
                            <th>Productos</th>
                            <th>Estado</th>
                            <th>Mensaje</th>
                            <th>Información de pedido / Factura</th>
                          </tr>
                        </thead>
                        <tbody>{/*afiliations*/}</tbody>
                      </Table>
                    </div>
                    <Col lg={12}>
                      <Table
                        columns={columnsUserOrders}
                        onChange={this.onChange}
                        dataSource={this.state.userOrders}
                        className="isoSearchableTable"
                        key={this.state.comitesUserGet}
                      />
                    </Col>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}
export default withRouter(ColectivoUser);
