import React, { Fragment } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { Table as tableBoostrapt } from 'react-bootstrap';
import { Table } from 'antd';
import TopImageTitle from '../../components/objects/topImageTitle';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import ReactGA from 'react-ga';
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  Image,
} from '@react-pdf/renderer';
import SidebarProfile from './components/sidebar';
import SEO from '../../components/objects/seo';
import { Divider } from 'antd';

class BasicUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: [],
      islogged: 0,
      statusPage: 0,
      userOrders: [],
      pageUpdate: '',
      comitesUserGet: [],
      key: this.props.location.state
        ? this.props.location.state.key
        : 'personalData',
      personalDataNotComplete: false,
      profesionalDataNotComplete: false,
      msgCompleteProfilePersonal: null,
      msgCompleteProfileProfesional: null,
      msgCompleteProfileSettings: null,
      msgCompleteProfile: null,
      msgRenovations: null,
      beginIban: '',
      endIban: '',
      showLinkToPDF: false,
      numComitesPagoPDF: 0,
      userComitesAdmin: [],
      fetchingAdminOrder: false,
      phase: '',
      phaseAssembly: '',
      idVotationAssembly: '',
      idVotation: '',
      fetchInformation: true,
      numberIteration: 0,
      renovableOrder: [],
      orderForSidebar: [],
      messageCompletetoAfilitation: 0,
      candidatureComiteExecutive: 0,
      alreadyRenovate: 1,
      allowAddComites: false
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(key) {
    this.setState({ key });
  }
  checkTokken = async () => {
    const jwt = localStorage.getItem('access_token');

    if (!jwt) {
      this.setState.islogged = 0;
    } else {
      this.setState.islogged = 1;
      let formData = new FormData();
      formData.append('jwt', jwt);
      await axios
        .post('/api/validate_token', formData)
        .then((res) => {
          //console.log(res);
          this.setState({
            userInfo: res.data.data,
          });
        })
        .catch((error) => {
          this.setState({
            statusPage: 1,
          });
        });
    }
  };
  getOrdersUser = async () => {
    const { userInfo } = this.state;
    var userId = userInfo.id;
    let formData = new FormData();
    formData.append('id_user', userId);
    await axios
      .post('/api/payment_get_by_id_user', formData)
      .then((res) => {
        //console.log(res);
        this.setState({
          userOrders: res.data.records,
          numberofOrders: res.data.records.length,
        });
        this.checkForRenew(res.data.records);
      })
      .catch((error) => {});
  };
  setMessageWarning() {
    const { userInfo } = this.state;
    console.log(userInfo);
    //Message user Personal data
    if (userInfo) {
      if (!userInfo.name || !userInfo.lastname || !userInfo.nif) {
        this.setState({
          msgCompleteProfilePersonal: (
            <span>
              Si va a realizar una afiliación, debe rellenar los datos con
              asterisco de todas las pestañas.
              <br />
              Si va a realizar cualquier otra gestión, sólo es necesario
              rellenar los campos con asterisco de la pestaña “Datos
              personales”.
              <br />
            </span>
          ),
        });
      } else {
        this.setState({
          msgCompleteProfilePersonal: null,
        });
      }
    } else {
      this.setState({
        msgCompleteProfilePersonal: null,
      });
    }
    //Message user profesional data
    if (userInfo) {
      if (
        !userInfo.org ||
        !userInfo.job ||
        !userInfo.education ||
        !userInfo.academic_degree
      ) {
        this.setState({
          msgCompleteProfileProfesional: (
            <div>
              <p>
                Enhorabuena, ya puede proceder con sus gestiones en la web de
                FES
              </p>
              <p>
                Recuerde que, si desea afiliarse, deberá completar los datos que
                se solicitan en el resto de apartados del formulario.
              </p>
              <p>
                Si desea registrarte para recibir nuestro boletín de noticias,
                puede hacerlo desde el apartado “Ajustes”.
              </p>
            </div>
          ),
        });
      } else {
        this.setState({
          msgCompleteProfileProfesional: null,
        });
      }
    } else {
      this.setState({
        msgCompleteProfileProfesional: null,
      });
    }
    //Message user settings data
    if (userInfo) {
      if (+userInfo.newsletter === 2 || +userInfo.type_status === 2) {
        this.setState({
          msgCompleteProfileSettings: null,
        });
      } else {
        this.setState({
          msgCompleteProfileSettings: null,
        });
      }
    } else {
      this.setState({
        msgCompleteProfileSettings:
          'Le recordamos que para realizar una afiliación es necesario completar la pestaña ajustes.',
      });
    }
  }
  profileComplete() {
    const { msgCompleteProfileProfesional } = this.state;
    const { msgCompleteProfilePersonal } = this.state;
    const { msgCompleteProfileSettings } = this.state;

    if (
      msgCompleteProfileProfesional === null &&
      msgCompleteProfilePersonal === null &&
      msgCompleteProfileSettings === null
    ) {
      this.setState({
        msgCompleteProfile: '',
      });
    } else {
      this.setState({
        msgCompleteProfile: null,
      });
    }
  }
  getComitesAdmin = async () => {
    const { userInfo } = this.state;
    var userId = userInfo.id;
    let formData = new FormData();
    this.setState({
      fetchingAdminOrder: true,
    });

    formData.append('id', userId);

    await axios
      .post('/api/users_comites_admin_get_by_id', formData)
      .then((res) => {
        this.setState({
          userComitesAdmin: res.data.records,
          fetchingAdminOrder: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchingAdminOrder: false,
        });
      });
  };
  checkDateVotation = async () => {
    this.setState({
      fetchInformation: true,
    });
    await axios
      .post('/api/votes_check_dates')

      .then((res) => {
        this.setState({
          phase: res.data.records[1].phase,
          idVotation: res.data.records[0].id,
          fetchInformation: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchInformation: false,
        });
      });
  };
  checkDateVotationAssembly = async () => {
    this.setState({
      fetchInformation: true,
    });
    await axios
      .post('/api/votes_a_check_dates')

      .then((res) => {
        this.setState({
          phaseAssembly: res.data.records[1].phase,
          idVotationAssembly: res.data.records[0].id,
          fetchInformation: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchInformation: false,
        });
      });
  };
  getComitesUserActual = async () => {
    const { userInfo } = this.state;
    var userId = userInfo.id;
    let formData = new FormData();
    formData.append('id', userId);

    await axios
      .post('/api/users_comites_current_year_get_by_id', formData)
      .then((res) => {
        this.setState({
          comitesUserGet: res.data.records,
        });
      })
      .catch((error) => {});
  };
  getNewSidebar = async () => {
    let formData = new FormData();
    this.setState({
      fetchingAdminOrder: true,
    });
    formData.append('id_categ', 21);
    await axios
      .post('/api/news_get_by_id_categ', formData)
      .then((res) => {
        this.setState({
          newSidebar: res.data.records,
          fetchingAdminOrder: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchingAdminOrder: false,
        });
      });
  };
  checkIfAlreadyRenovate = async () => {
    let formData = new FormData();
    this.setState({
      fetchInformation: true,
    });
    formData.append('id_user', this.state.userInfo.id);

    await axios
      .post('/api/payment_renov_show', formData)
      .then((res) => {
        this.setState({
          alreadyRenovate: 0,
          fetchInformation: false,
        });
      })
      .catch((error) => {
        this.setState({
          alreadyRenovate: 1,
          fetchInformation: false,
        });
      });
  };
  componentDidMount() {
    (async () => {
      await this.checkTokken();
      await this.getComitesAdmin();
      await this.getOrdersUser();
      await this.setMessageWarning();
      await this.profileComplete();
      await this.checkDateVotation();
      await this.checkDateVotationAssembly();
      await this.getComitesUserActual();
      await this.getNewSidebar();
      await this.getInformationComiteExecutive();
      await this.checkIfAlreadyRenovate();
      this.transformIban();
    })();
    let status = this.props.match.params.status;

    if (status !== '' && status === 'renovation') {
      this.setState({ key: 'afiliationData' });
    }
    //Check if key is sent to set it for the tabs
    if (this.props.history.location.state) {
      this.setState({
        key: this.props.history.location.state.key,
      });
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  getInformationComiteExecutive = async () => {
    await axios
      .get('/api/votes_ce_config_valid_dates_get')
      .then((res) => {
        this.setState({
          candidatureComiteExecutive: 1,
        });
      })
      .catch((error) => {
        this.setState({
          candidatureComiteExecutive: 0,
        });
      });
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.msgCompleteProfileProfesional !==
      this.state.msgCompleteProfileProfesional
    ) {
      (async () => {
        await this.profileComplete();
      })();
    }
    if (
      prevState.msgCompleteProfilePersonal !==
      this.state.msgCompleteProfilePersonal
    ) {
      (async () => {
        await this.profileComplete();
      })();
    }
    if (
      prevState.msgCompleteProfileSettings !==
      this.state.msgCompleteProfileSettings
    ) {
      (async () => {
        await this.profileComplete();
      })();
    }
  }
  handleSubmitPersonalInfo = (event) => {
    event.preventDefault();
    this.refs.btnPersonal.setAttribute('disabled', 'disabled');
    var nameError = 0;
    var dniError = 0;
    var bornCountryUserError = 0;
    var cityUserError = 0;
    var stateUserError = 0;
    var residencesUserError = 0;
    var lastNameUser = 0;
    let formData = new FormData();
    if (event.target.nameUser.value === '') {
      nameError = 'Necesita rellenar el nombre';
      //alert(nameError);
    } else if (event.target.dniUser.value === '') {
      dniError = 'Necesita rellenar el DNI';
      //alert(dniError);
    } else if (event.target.lastNameUser.value === '') {
      lastNameUser = 'Necesita rellenar el Apellido';
      //alert(residencesUserError);
    }
    if (
      nameError !== 0 ||
      dniError !== 0 ||
      bornCountryUserError !== 0 ||
      cityUserError !== 0 ||
      stateUserError !== 0 ||
      residencesUserError !== 0 ||
      lastNameUser !== 0
    ) {
      alert('Hay campos obligatorios sin rellenar');
      this.refs.btnPersonal.removeAttribute('disabled');
    } else if (/[^0-9a-zA-Z]/.test(event.target.dniUser.value)) {
      alert(
        'El DNI introducido contiene caracteres no permitidos. Recuerde que el DNI solo puede contener números y letras.'
      );
      this.refs.btnPersonal.removeAttribute('disabled');
    } else {
      formData.append('jwt', localStorage.getItem('access_token'));
      formData.append('name', event.target.nameUser.value);
      formData.append('lastname', event.target.lastNameUser.value);
      formData.append('nif', event.target.dniUser.value);
      formData.append('birthdate', event.target.yearBornUser.value);
      formData.append('country', event.target.bornCountryUser.value);
      formData.append('gender', event.target.genderUser.value);
      formData.append('address_1', event.target.adressUser.value);
      formData.append('city', event.target.cityUser.value);
      formData.append('region', event.target.stateUser.value);
      formData.append('country_residence', event.target.residencesUser.value);
      formData.append('landline', event.target.telUser.value);
      formData.append('phone', event.target.mobileUser.value);
      formData.append('postal_code', event.target.cpUser.value);
      formData.append('id_type', this.state.userInfo.id_type);
      localStorage.setItem('usernameShow', event.target.nameUser.value);
      axios
        .post('/api/users_update_personal_data', formData)
        .then((response) => {
          //console.log(res);
          if (response.status === 200) {
            let jwt = response.data.jwt;
            localStorage.setItem('access_token', jwt);
          }

          alert('Se han modificado correctamente sus datos');
          (async () => {
            await this.checkTokken();
            await this.setMessageWarning();
            this.refs.btnPersonal.removeAttribute('disabled');
          })();
          this.setState({ key: 'profesionalData' });
        })
        .catch((error) => {
          alert('No se ha podido modificar los datos');
          this.refs.btnPersonal.removeAttribute('disabled');
        });
    }
  };
  handleSubmitProfesionalInfo = (event) => {
    event.preventDefault();
    this.refs.btnProfesional.setAttribute('disabled', 'disabled');
    var orgError = 0;
    var fileError = 0;
    var educationError = 0;
    var jobError = 0;

    if (event.target.org.value === '' || event.target.org.value === '01') {
      orgError = 'Necesita rellenar el org';
    } else if (
      event.target.education.value === '' ||
      event.target.education.value === '01'
    ) {
      educationError = 'Necesita rellenar el pais de nacimiento';
    } else if (event.target.job.value === '') {
      jobError = 'Necesita rellenar la ciudad';
    }
    if (
      orgError !== 0 ||
      fileError !== 0 ||
      educationError !== 0 ||
      jobError !== 0
    ) {
      alert('Hay campos obligatorios sin rellenar');
      this.refs.btnProfesional.removeAttribute('disabled');
    } else {
      let formData = new FormData();

      formData.append('jwt', localStorage.getItem('access_token'));
      formData.append('org', event.target.org.value);
      formData.append('org_extra', event.target.org_extra.value);
      formData.append('file', event.target.doc_url.files[0]);
      formData.append('education', event.target.education.value);
      formData.append('job', event.target.job.value);
      formData.append('cv_description', event.target.linkedin.value);
      formData.append('academic_degree', event.target.academic_degree.value);

      axios
        .post('/api/users_update_profesional_data', formData)
        .then((response) => {
          if (response.status === 200) {
            let jwt = response.data.jwt;
            localStorage.setItem('access_token', jwt);
          }
          alert('Se han modificado correctamente sus datos');
          (async () => {
            await this.checkTokken();
            await this.setMessageWarning();
            this.refs.btnProfesional.removeAttribute('disabled');
          })();
          this.setState({ key: 'settingData' });
        })
        .catch((error) => {
          alert('No se ha podido modificar los datos');
          this.refs.btnProfesional.removeAttribute('disabled');
        });
    }
  };
  handleSettingsProfile = (event) => {
    event.preventDefault();
    this.refs.btnSettings.setAttribute('disabled', 'disabled');

    var publicProfile = 0;
    var joinNewsletter = 0;
    let formData = new FormData();
    if (event.target.publicProfile.value === '') {
      publicProfile = 'Campo perfil publico';
      //alert(nameError);
    } else if (event.target.joinNewsletter.value === '') {
      joinNewsletter = 'Campo newsletter';
      //alert(dniError);
    }
    if (publicProfile !== 0 || joinNewsletter !== 0) {
      alert('Hay campos obligatorios sin rellenar');
      this.refs.btnSettings.removeAttribute('disabled');
    } else {
      formData.append('jwt', localStorage.getItem('access_token'));
      formData.append('newsletter', event.target.joinNewsletter.value);
      formData.append('type_status', event.target.publicProfile.value);
      if (+event.target.joinNewsletter.value === 1) {
        let formData2 = new FormData();

        formData2.append('email', this.state.userInfo.email);

        axios
          .post('/api/addEmailToBoletin', formData2)
          .then((response) => {
            if (response.status === 200) {
            }
          })
          .catch((error) => {});
      } else if (+event.target.joinNewsletter.value === 0) {
        let formData2 = new FormData();
        formData2.append('email', this.state.userInfo.email);

        axios
          .post('/api/deleteEmailFromBoletin', formData2)
          .then((response) => {
            if (response.status === 200) {
            }
          })
          .catch((error) => {});
      }

      axios
        .post('/api/users_update_settings_data', formData)
        .then((response) => {
          //console.log(res);
          if (response.status === 200) {
            let jwt = response.data.jwt;
            localStorage.setItem('access_token', jwt);
            this.setState({ key: 'afiliationData' });
            this.setState({
              messageCompletetoAfilitation: 1,
            });
          }
          alert('Se han modificado correctamente sus datos');
          (async () => {
            await this.checkTokken();
            await this.setMessageWarning();
            this.refs.btnSettings.removeAttribute('disabled');
          })();
        })
        .catch((error) => {
          alert('No se ha podido modificar los datos');
          this.refs.btnSettings.removeAttribute('disabled');
        });
    }
  };
  handleSubmitBankInfo = (event) => {
    event.preventDefault();
    this.refs.btnBank.setAttribute('disabled', 'disabled');

    var ibanBegin = 0;
    var ibanEnd = 0;
    var swift = 0;
    let formData = new FormData();
    if (event.target.ibanBegin.value === '') {
      ibanBegin = 'Campo Iban';
      //alert(nameError);
    } else if (event.target.swift.value === '') {
      swift = 'Campo swift';
      //alert(dniError);
    } else if (event.target.ibanEnd.value === '') {
      ibanEnd = 'Campo Cuenta bancaria';
      //alert(dniError);
    }
    if (ibanBegin !== 0 || ibanEnd !== 0 || swift !== 0) {
      alert('Hay campos obligatorios sin rellenar');
      this.refs.btnBank.removeAttribute('disabled');
    } else if (event.target.ibanBegin.value.length < 4) {
      alert('El Iban tiene que tener 4 caracteres');
      this.refs.btnBank.removeAttribute('disabled');
    } else if (event.target.ibanEnd.value.length < 20) {
      alert('El número de cuenta tiene que tener 20 digitos');
      this.refs.btnBank.removeAttribute('disabled');
    } else {
      var FullIban = event.target.ibanBegin.value + event.target.ibanEnd.value;
      formData.append('jwt', localStorage.getItem('access_token'));
      formData.append('iban', FullIban);
      formData.append('swift', event.target.swift.value);

      axios
        .post('/api/users_update_bank_data', formData)
        .then((response) => {
          if (response.status === 200) {
            let jwt = response.data.jwt;
            localStorage.setItem('access_token', jwt);
          }
          alert('Se han modificado correctamente sus datos');
          (async () => {
            await this.checkTokken();
            await this.setMessageWarning();
            this.refs.btnBank.removeAttribute('disabled');
          })();
        })
        .catch((error) => {
          alert('No se ha podido modificar los datos');
          this.refs.btnBank.removeAttribute('disabled');
        });
    }
  };
  changeOptionGender = (event) => {
    this.setState((prevState) => ({
      userInfo: {
        ...prevState.userInfo, //Mantener el resto de datos
        gender: this.refs.genderUser.value, // Modificar el dato que queremos
      },
    }));
  };
  changeOptionLvlStudies = (event) => {
    this.setState((prevState) => ({
      userInfo: {
        ...prevState.userInfo, //Mantener el resto de datos
        education: this.refs.education.value, // Modificar el dato que queremos
      },
    }));
  };
  changeOptionActualInstitution = (event) => {
    this.setState((prevState) => ({
      userInfo: {
        ...prevState.userInfo, //Mantener el resto de datos
        org: this.refs.org.value, // Modificar el dato que queremos
      },
    }));
  };
  changeNewsletterJoin = (event) => {
    const valueforNJ = event.target.value;
    this.setState((prevState) => ({
      userInfo: {
        ...prevState.userInfo, //Mantener el resto de datos
        newsletter: valueforNJ, // Modificar el dato que queremos
      },
    }));
  };
  publicProfileRadio = (event) => {
    const valueforPP = event.target.value;
    this.setState((prevState) => ({
      userInfo: {
        ...prevState.userInfo, //Mantener el resto de datos
        type_status: valueforPP, // Modificar el dato que queremos
      },
    }));
  };
  transformIban() {
    const { userInfo } = this.state;
    if (userInfo) {
      if (userInfo.iban) {
        var beginIban;
        var endIban;
        beginIban = userInfo.iban.toString();
        beginIban = beginIban.slice(0, 4);
        endIban = userInfo.iban.toString();
        endIban = endIban.slice(4, 30);
        this.setState({
          beginIban: beginIban,
          endIban: endIban,
        });
      } else {
        this.setState({
          beginIban: 'ES',
          endIban: '',
        });
      }
    }
  }
  handleChangeFocus(e) {
    if (e.target.value.length > 3) {
      document.getElementById('ibanEnd').focus();
    }
  }
  setshowLinkToPDF(e, comites) {
    if (comites.length > 0) {
      var numComitesPago = 0;
      comites.map((com, index) => {
        if (+com.type === 1) {
          numComitesPago++;
        }
        return null;
      });
      this.setState({
        numComitesPagoPDF: numComitesPago,
        showLinkToPDF: true,
      });
    } else {
      this.setState({
        numComitesPagoPDF: 0,
        showLinkToPDF: true,
      });
    }
  }
  setnotshowLinkToPDF(e) {
    this.setState({
      showLinkToPDF: false,
    });
  }
  setRenovationMesagge = () => {
    this.setState({
      msgRenovations:
        'Se ha abierto el plazo de renovación de AFILIACIÓN A FES. Si usted estuvo afiliado/a el año pasado puede entrar en la pestaña PEDIDOS y pulsando sobre el botón "Iniciar renovación" podrá renovar de nuevo su afiliación.',
    });
  };
  checkForRenew = (arrayofItems) => {
    this.setState({
      fetchingAdminOrder: true,
    });
    var temp_array = [];
    var temp_array_year = [];
    arrayofItems.forEach((item) => {
      if (+item.id_template !== 11 && +item.id_template !== 12) {
        var id = item.id;
        let formData = new FormData();
        formData.append('id', item.id);
        axios
          .post('/api/payment_renov_get', formData)
          .then((res) => {
            temp_array.push(id);
            temp_array_year.push(id);
            this.setRenovationMesagge();
          })
          .catch((error) => {
            if (error.response.data.message === 'Not today') {
              temp_array_year.push(id);
              return null;
            } else if (
              error.response.data.records.message ===
              'Date ok, but this order is already renovated'
            ) {
              temp_array_year.push(id);
            }
          });
      }
    });
    this.setState({
      renovableOrder: temp_array,
      orderForSidebar: temp_array_year,
      fetchingAdminOrder: false,
    });
  };
  checkIfRenovable = (id) => {
    //console.log(id);
    if (this.state.renovableOrder.includes(id)) {
      //console.log(id);
      return true;
    } else {
      //console.log(id);
      return false;
    }
  };
  checkDatePayment = (dateToconvert) => {
    if (dateToconvert) {
      var date = dateToconvert;
      date = date.split(' ')[0];
      var yyyy = date.split('-')[0];
      var mmmm = date.split('-')[1];
      var dddd = date.split('-')[2];
      date = dddd + '-' + mmmm + '-' + yyyy;
      return date;
    }
  };
  freeSpotAvaliable = (numberofComites, paymentId, state) => {
    //var countFreeComites = 0;
    /*
		0-> Add free Comites
		1-> Add Pay Comites
		2-> Can't add comites
		*/
    if (+this.state.userInfo.id_type === 2) {
      if (+state !== 3) {
        return 2;
      } else if (
        this.state.comitesUserGet.length < 4 &&
        this.state.comitesUserGet.length >= 0
      ) {
        return 0;
      } else if (this.state.comitesUserGet.length >= 4) {
        return 1;
      } else {
        return 2;
      }
    } else {
      if (+state !== 3) {
        return 2;
      } else if (
        this.state.comitesUserGet.length < 4 &&
        this.state.comitesUserGet.length >= 0
      ) {
        return 1;
      } else if (this.state.comitesUserGet.length >= 4) {
        return 1;
      } else {
        return 2;
      }
    }

    /*
			
			for (var i = 0; i < numberofComites.length; i++) {
				if (numberofComites[i].type) {
					countFreeComites++;
				}
			}
			if (countFreeComites > 3) {
				return true;
			} else {
				return false;
			}
				}
			*/
  };

  messageByState = (
    idState,
    idOrder,
    idRenovation,
    idProduct,
    observations
  ) => {
    if (+idState === 6) {
      return (
        <div key={idOrder}>
          {this.checkIfRenovable(idRenovation) === true ? (
            <Link
              to={{
                pathname: `/producto/${idProduct}`,
                state: {
                  statusDoc: 1,
                  productID_confirmation: idProduct,
                  id_payment: idOrder,
                  renov_id: idRenovation,
                  renovationTime: true,
                },
              }}
            >
              Renovación pendiente de pago
            </Link>
          ) : (
            <Link
              to={{
                pathname: `/producto/${idProduct}`,
                state: {
                  statusDoc: 1,
                  productID_confirmation: idProduct,
                  id_payment: idOrder,
                  renov_id: idRenovation,
                },
              }}
            >
              Pedido pendiente
            </Link>
          )}
        </div>
      );
    } else if (+idState === 3) {
      return <div key={idOrder}>{observations}</div>;
    } else if (+idState === 7) {
      return (
        <div>
          {observations ? (
            <Fragment>
              {' '}
              {observations}
              <hr></hr>
              <Link
                to={{
                  pathname: `/producto/${idProduct}`,
                  state: {
                    statusDoc: 3,
                    productID_confirmation: idProduct,
                    id_payment: idOrder,
                    renov_id: idRenovation,
                  },
                }}
              >
                {' '}
                <div>Enviar Documentación</div>
              </Link>
            </Fragment>
          ) : (
            <Link
              to={{
                pathname: `/producto/${idProduct}`,
                state: {
                  statusDoc: 3,
                  productID_confirmation: idProduct,
                  id_payment: idOrder,
                },
              }}
            >
              {' '}
              <div>Enviar Documentación</div>
            </Link>
          )}
        </div>
      );
    } else if (+idState === 11) {
      return (
        <div>
          <Fragment>
            {' '}
            {observations}
            <Link
              to={{
                pathname: `/producto/${idProduct}`,
                state: {
                  productID_confirmation: idProduct,
                  id_payment: idOrder,
                  renovationTime: true,
                  renov_id: idRenovation,
                },
              }}
            >
              {' '}
              <div className="button">Iniciar proceso de renovación</div>
            </Link>
          </Fragment>
        </div>
      );
    } else if (+idState === 8) {
      return null;
    } else if (+idState === 12) {
      return (
        <div key={idOrder}>
          {this.checkIfRenovable(idRenovation) === true ? (
            <Link
              to={{
                pathname: `/producto/${idProduct}`,
                state: {
                  statusDoc: 1,
                  productID_confirmation: idProduct,
                  id_payment: idOrder,
                  renov_id: idRenovation,
                  renovationTime: true,
                },
              }}
            >
              Renovación pendiente de pago
            </Link>
          ) : (
            <Link
              to={{
                pathname: `/producto/${idProduct}`,
                state: {
                  statusDoc: 1,
                  productID_confirmation: idProduct,
                  id_payment: idOrder,
                  renov_id: idRenovation,
                  rePay: idOrder,
                },
              }}
            >
              Ir a Pago
            </Link>
          )}
        </div>
      );
    } else {
      return (
        <div key={idOrder}>
          <td>{observations}</td>
        </div>
      );
    }
  };

  orderInformationInvoice = (
    idState,
    idOrder,
    idRenovation,
    idProduct,
    observations,
    record,
    userInfo,
    preName,
    ReceiptPdf
  ) => {
    if (+record.id_state === 6) {
      var showModal = showModal + record.id;
      return (
        <div>
          <p
            onClick={() => this.setState({ [showModal]: true })}
            className="linkStyle"
          >
            Información del pedido
          </p>
          <Modal
            show={this.state[showModal]}
            onHide={() =>
              this.setState({ [showModal]: false, showLinkToPDF: false })
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Resumen del pedido
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <tableBoostrapt
                striped
                bordered
                hover
                responsive
                className="tablePopUp"
              >
                <thead>
                  <tr>
                    <th>Nombre del Producto</th>
                    <th>Fecha</th>
                    <th>Precio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Fragment>
                        {!preName
                          ? record.product_name +
                            '  (' +
                            record.payment_year +
                            ')'
                          : preName + ' (' + record.payment_year + ')'}
                      </Fragment>
                    </td>
                    <td>{record.initial_payment}</td>
                    <td>{+record.price / 100}€</td>
                  </tr>
                </tbody>
              </tableBoostrapt>
              <p className="infoResumeOrder">Nombre: {userInfo.name}</p>
              <p className="infoResumeOrder">Apellidos: {userInfo.lastname}</p>
              <p className="infoResumeOrder">
                Forma de pago: {record.payment_type_name}
              </p>
              <p className="infoResumeOrder">
                Estado del pago: {record.state_name}
              </p>
              <div>
                {this.state.showLinkToPDF ? (
                  <PDFDownloadLink
                    document={
                      <ReceiptPdf
                        payment_year={record.payment_year}
                        nombre={userInfo.name}
                        apellido={userInfo.lastname}
                        cantidad={+record.price / 100}
                        nameProduct={!preName ? record.product_name : preName}
                        formPayment={record.payment_type_name}
                        idPedido={record.id}
                        date={this.checkDatePayment(record.initial_payment)}
                        comites={record.comites}
                        tipoPago={record.payment_type}
                        idState={record.id_state}
                        idTemplate={record.id_template}
                        iva={record.iva}
                      />
                    }
                    fileName={'recibo_numero_pedido_' + record.id + '.pdf'}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <button className="button margin-top-20">
                          Cargando Recibo...
                        </button>
                      ) : (
                        <button
                          className="button margin-top-20"
                          onClick={() => this.setnotshowLinkToPDF()}
                        >
                          Descargar Recibo
                        </button>
                      )
                    }
                  </PDFDownloadLink>
                ) : (
                  <Button
                    className="button margin-top-20"
                    onClick={(e) => this.setshowLinkToPDF(e, record.comites)}
                  >
                    Preparar Recibo para descarga
                  </Button>
                )}
                {record.invoice ? (
                  <a
                    href={record.invoice}
                    target="_blank"
                    rel="noreferrer noopener"
                    download={'Factura - ' + record.id}
                  >
                    <Button className="button margin-top-20">
                      Obtener factura
                    </Button>
                  </a>
                ) : null}
              </div>
              <hr></hr>
              {+record.id_template === 5 ? (
                ''
              ) : (
                <span>
                  {record.comites.length > 0 ? (
                    <div>
                      {' '}
                      <p>Listado de comités Incluidos</p>{' '}
                      <tableBoostrapt
                        striped
                        bordered
                        hover
                        className="comiteTableProfile tablePopComite"
                      >
                        <thead>
                          <tr>
                            <th>Nombre</th>
                          </tr>
                          <tbody>
                            {record.comites.map((com, index) => {
                              return (
                                <tr key={index}>
                                  <td>{com.title}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </thead>
                      </tableBoostrapt>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </span>
              )}
            </Modal.Body>
          </Modal>
        </div>
      );
    } else if (+record.id_state === 3) {
      var showModal2 = showModal + record.id;
      return (
        <div key={record.id}>
          <p
            onClick={() => this.setState({ [showModal2]: true })}
            className="linkStyle"
          >
            Información del pedido
          </p>

          <Modal
            show={this.state[showModal2]}
            onHide={() =>
              this.setState({ [showModal2]: false, showLinkToPDF: false })
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Resumen del pedido
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <tableBoostrapt
                striped
                bordered
                hover
                responsive
                className="tablePopUp"
              >
                <thead>
                  <tr>
                    <th>Nombre del Producto</th>
                    <th>Fecha</th>
                    <th>Precio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Fragment>
                        {!preName
                          ? record.product_name +
                            '  (' +
                            record.payment_year +
                            ')'
                          : preName + ' (' + record.payment_year + ')'}
                      </Fragment>
                    </td>
                    <td>{record.initial_payment}</td>
                    <td>{+record.price / 100}€</td>
                  </tr>
                </tbody>
              </tableBoostrapt>
              <p className="infoResumeOrder">Nombre: {userInfo.name}</p>
              <p className="infoResumeOrder">Apellidos: {userInfo.lastname}</p>
              <p className="infoResumeOrder">
                Forma de pago: {record.payment_type_name}
              </p>
              <p className="infoResumeOrder">
                Estado del pago: {record.state_name}
              </p>
              <div>
                {this.state.showLinkToPDF ? (
                  <PDFDownloadLink
                    document={
                      <ReceiptPdf
                        payment_year={record.payment_year}
                        nombre={userInfo.name}
                        apellido={userInfo.lastname}
                        cantidad={+record.price / 100}
                        nameProduct={!preName ? record.product_name : preName}
                        formPayment={record.payment_type_name}
                        idPedido={record.id}
                        date={this.checkDatePayment(record.initial_payment)}
                        comites={record.comites}
                        tipoPago={record.payment_type}
                        idState={record.id_state}
                        idTemplate={record.id_template}
                        iva={record.iva}
                      />
                    }
                    fileName={'recibo_numero_pedido_' + record.id + '.pdf'}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <button className="button margin-top-20">
                          Cargando Recibo...
                        </button>
                      ) : (
                        <button
                          className="button margin-top-20"
                          onClick={() => this.setnotshowLinkToPDF()}
                        >
                          Descargar Recibo
                        </button>
                      )
                    }
                  </PDFDownloadLink>
                ) : (
                  <Button
                    className="button margin-top-20"
                    onClick={(e) => this.setshowLinkToPDF(e, record.comites)}
                  >
                    Preparar Recibo para descarga
                  </Button>
                )}
                {record.invoice ? (
                  <a
                    href={record.invoice}
                    target="_blank"
                    rel="noreferrer noopener"
                    download={'Factura - ' + record.id}
                  >
                    <Button className="button margin-top-20">
                      Obtener factura
                    </Button>
                  </a>
                ) : null}
              </div>
              <hr></hr>
              {+record.id_template === 5 ? (
                ''
              ) : (
                <span>
                  {record.comites.length > 0 ? (
                    <div>
                      {' '}
                      <p>Listado de comités Incluidos</p>{' '}
                      <tableBoostrapt
                        striped
                        bordered
                        hover
                        className="comiteTableProfile tablePopComite"
                      >
                        <thead>
                          <tr>
                            <th>Nombre</th>
                          </tr>
                          <tbody>
                            {record.comites.map((com, index) => {
                              return (
                                <tr key={index}>
                                  <td>{com.title}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </thead>
                      </tableBoostrapt>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </span>
              )}
            </Modal.Body>
          </Modal>
        </div>
      );
    } else if (+record.id_state === 7) {
      var showModal4 = showModal + record.id;
      return (
        <div key={record.id}>
          <p
            onClick={() => this.setState({ [showModal4]: true })}
            className="linkStyle"
          >
            Información del pedido
          </p>
          <Modal
            show={this.state[showModal4]}
            onHide={() =>
              this.setState({ [showModal4]: false, showLinkToPDF: false })
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Resumen del pedido
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <tableBoostrapt
                striped
                bordered
                hover
                responsive
                className="tablePopUp"
              >
                <thead>
                  <tr>
                    <th>Nombre del Producto</th>
                    <th>Fecha</th>
                    <th>Precio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Fragment>
                        {!preName
                          ? record.product_name +
                            '  (' +
                            record.payment_year +
                            ')'
                          : preName + ' (' + record.payment_year + ')'}
                      </Fragment>
                    </td>
                    <td>{record.initial_payment}</td>
                    <td>{+record.price / 100}€</td>
                  </tr>
                </tbody>
              </tableBoostrapt>
              <p className="infoResumeOrder">Nombre: {userInfo.name}</p>
              <p className="infoResumeOrder">Apellidos: {userInfo.lastname}</p>
              <p className="infoResumeOrder">
                Forma de pago: {record.payment_type_name}
              </p>
              <p className="infoResumeOrder">
                Estado del pago: {record.state_name}
              </p>
              <div>
                {this.state.showLinkToPDF ? (
                  <PDFDownloadLink
                    document={
                      <ReceiptPdf
                        payment_year={record.payment_year}
                        nombre={userInfo.name}
                        apellido={userInfo.lastname}
                        cantidad={+record.price / 100}
                        nameProduct={!preName ? record.product_name : preName}
                        formPayment={record.payment_type_name}
                        idPedido={record.id}
                        date={this.checkDatePayment(record.initial_payment)}
                        comites={record.comites}
                        tipoPago={record.payment_type}
                        idState={record.id_state}
                        idTemplate={record.id_template}
                        iva={record.iva}
                      />
                    }
                    fileName={'recibo_numero_pedido_' + record.id + '.pdf'}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <button className="button margin-top-20">
                          Cargando Recibo...
                        </button>
                      ) : (
                        <button
                          className="button margin-top-20"
                          onClick={() => this.setnotshowLinkToPDF()}
                        >
                          Descargar Recibo
                        </button>
                      )
                    }
                  </PDFDownloadLink>
                ) : (
                  <Button
                    className="button margin-top-20"
                    onClick={(e) => this.setshowLinkToPDF(e, record.comites)}
                  >
                    Preparar Recibo para descarga
                  </Button>
                )}
                {record.invoice ? (
                  <a
                    href={record.invoice}
                    target="_blank"
                    rel="noreferrer noopener"
                    download={'Factura - ' + record.id}
                  >
                    <Button className="button margin-top-20">
                      Obtener factura
                    </Button>
                  </a>
                ) : null}
              </div>
              <hr></hr>
              {+record.id_template === 5 ? (
                ''
              ) : (
                <span>
                  {record.comites.length > 0 ? (
                    <div>
                      {' '}
                      <p>Listado de comités Incluidos</p>{' '}
                      <tableBoostrapt
                        striped
                        bordered
                        hover
                        className="comiteTableProfile tablePopComite"
                      >
                        <thead>
                          <tr>
                            <th>Nombre</th>
                          </tr>
                          <tbody>
                            {record.comites.map((com, index) => {
                              return (
                                <tr key={index}>
                                  <td>{com.title}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </thead>
                      </tableBoostrapt>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </span>
              )}
            </Modal.Body>
          </Modal>
        </div>
      );
    } else if (+record.id_state === 11) {
      var showModal10 = showModal + record.id;
      return (
        <div key={record.id}>
          <p
            onClick={() => this.setState({ [showModal10]: true })}
            className="linkStyle"
          >
            Información del pedido
          </p>
          <Modal
            show={this.state[showModal10]}
            onHide={() =>
              this.setState({ [showModal10]: false, showLinkToPDF: false })
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Resumen del pedido
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <tableBoostrapt
                striped
                bordered
                hover
                responsive
                className="tablePopUp"
              >
                <thead>
                  <tr>
                    <th>Nombre del Producto</th>
                    <th>Fecha</th>
                    <th>Precio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Fragment>
                        {!preName
                          ? record.product_name +
                            '  (' +
                            record.payment_year +
                            ')'
                          : preName + ' (' + record.payment_year + ')'}
                      </Fragment>
                    </td>
                    <td>{record.initial_payment}</td>
                    <td>{+record.price / 100}€</td>
                  </tr>
                </tbody>
              </tableBoostrapt>
              <p className="infoResumeOrder">Nombre: {userInfo.name}</p>
              <p className="infoResumeOrder">Apellidos: {userInfo.lastname}</p>
              <p className="infoResumeOrder">
                Forma de pago: {record.payment_type_name}
              </p>
              <p className="infoResumeOrder">
                Estado del pago: {record.state_name}
              </p>
              <div>
                {this.state.showLinkToPDF ? (
                  <PDFDownloadLink
                    document={
                      <ReceiptPdf
                        payment_year={record.payment_year}
                        nombre={userInfo.name}
                        apellido={userInfo.lastname}
                        cantidad={+record.price / 100}
                        nameProduct={!preName ? record.product_name : preName}
                        formPayment={record.payment_type_name}
                        idPedido={record.id}
                        date={this.checkDatePayment(record.initial_payment)}
                        comites={record.comites}
                        tipoPago={record.payment_type}
                        idState={record.id_state}
                        idTemplate={record.id_template}
                        iva={record.iva}
                      />
                    }
                    fileName={'recibo_numero_pedido_' + record.id + '.pdf'}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <button className="button margin-top-20">
                          Cargando Recibo...
                        </button>
                      ) : (
                        <button
                          className="button margin-top-20"
                          onClick={() => this.setnotshowLinkToPDF()}
                        >
                          Descargar Recibo
                        </button>
                      )
                    }
                  </PDFDownloadLink>
                ) : (
                  <Button
                    className="button margin-top-20"
                    onClick={(e) => this.setshowLinkToPDF(e, record.comites)}
                  >
                    Preparar Recibo para descarga
                  </Button>
                )}
                {record.invoice ? (
                  <a
                    href={record.invoice}
                    target="_blank"
                    rel="noreferrer noopener"
                    download={'Factura - ' + record.id}
                  >
                    <Button className="button margin-top-20">
                      Obtener factura
                    </Button>
                  </a>
                ) : null}
              </div>
              <hr></hr>
              {+record.id_template === 5 ? (
                ''
              ) : (
                <span>
                  {record.comites.length > 0 ? (
                    <div>
                      {' '}
                      <p>Listado de comités Incluidos</p>{' '}
                      <tableBoostrapt
                        striped
                        bordered
                        hover
                        className="comiteTableProfile tablePopComite"
                      >
                        <thead>
                          <tr>
                            <th>Nombre</th>
                          </tr>
                          <tbody>
                            {record.comites.map((com, index) => {
                              return (
                                <tr key={index}>
                                  <td>{com.title}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </thead>
                      </tableBoostrapt>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </span>
              )}
            </Modal.Body>
          </Modal>
        </div>
      );
    } else if (+record.id_state === 8) {
      return null;
    } else if (+record.id_state === 12) {
      var showModal12 = showModal + record.id;
      return (
        <div key={record.id}>
          <p
            onClick={() => this.setState({ [showModal12]: true })}
            className="linkStyle"
          >
            Información del pedido
          </p>
          <Modal
            show={this.state[showModal12]}
            onHide={() =>
              this.setState({ [showModal12]: false, showLinkToPDF: false })
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Resumen del pedido
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <tableBoostrapt
                striped
                bordered
                hover
                responsive
                className="tablePopUp"
              >
                <thead>
                  <tr>
                    <th>Nombre del Producto</th>
                    <th>Fecha</th>
                    <th>Precio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Fragment>
                        {!preName
                          ? record.product_name +
                            '  (' +
                            record.payment_year +
                            ')'
                          : preName + ' (' + record.payment_year + ')'}
                      </Fragment>
                    </td>
                    <td>{record.initial_payment}</td>
                    <td>{+record.price / 100}€</td>
                  </tr>
                </tbody>
              </tableBoostrapt>
              <p className="infoResumeOrder">Nombre: {userInfo.name}</p>
              <p className="infoResumeOrder">Apellidos: {userInfo.lastname}</p>
              <p className="infoResumeOrder">
                Forma de pago: {record.payment_type_name}
              </p>
              <p className="infoResumeOrder">
                Estado del pago: {record.state_name}
              </p>
              <div>
                {this.state.showLinkToPDF ? (
                  <PDFDownloadLink
                    document={
                      <ReceiptPdf
                        payment_year={record.payment_year}
                        nombre={userInfo.name}
                        apellido={userInfo.lastname}
                        cantidad={+record.price / 100}
                        nameProduct={!preName ? record.product_name : preName}
                        formPayment={record.payment_type_name}
                        idPedido={record.id}
                        date={this.checkDatePayment(record.initial_payment)}
                        comites={record.comites}
                        tipoPago={record.payment_type}
                        idState={record.id_state}
                        idTemplate={record.id_template}
                        iva={record.iva}
                      />
                    }
                    fileName={'recibo_numero_pedido_' + record.id + '.pdf'}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <button className="button margin-top-20">
                          Cargando Recibo...
                        </button>
                      ) : (
                        <button
                          className="button margin-top-20"
                          onClick={() => this.setnotshowLinkToPDF()}
                        >
                          Descargar Recibo
                        </button>
                      )
                    }
                  </PDFDownloadLink>
                ) : (
                  <Button
                    className="button margin-top-20"
                    onClick={(e) => this.setshowLinkToPDF(e, record.comites)}
                  >
                    Preparar Recibo para descarga
                  </Button>
                )}
                {record.invoice ? (
                  <a
                    href={record.invoice}
                    target="_blank"
                    rel="noreferrer noopener"
                    download={'Factura - ' + record.id}
                  >
                    <Button className="button margin-top-20">
                      Obtener factura
                    </Button>
                  </a>
                ) : null}
              </div>
              <hr></hr>
              {+record.id_template === 5 ? (
                ''
              ) : (
                <span>
                  {record.comites.length > 0 ? (
                    <div>
                      {' '}
                      <p>Listado de comités Incluidos</p>{' '}
                      <tableBoostrapt
                        striped
                        bordered
                        hover
                        className="comiteTableProfile tablePopComite"
                      >
                        <thead>
                          <tr>
                            <th>Nombre</th>
                          </tr>
                          <tbody>
                            {record.comites.map((com, index) => {
                              return (
                                <tr key={index}>
                                  <td>{com.title}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </thead>
                      </tableBoostrapt>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </span>
              )}
            </Modal.Body>
          </Modal>
        </div>
      );
    } else {
      var showModal3 = showModal + record.id;
      return (
        <div key={record.id}>
          <p
            onClick={() => this.setState({ [showModal3]: true })}
            className="linkStyle"
          >
            Información del pedido
          </p>
          <Modal
            show={this.state[showModal3]}
            onHide={() =>
              this.setState({ [showModal3]: false, showLinkToPDF: false })
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Resumen del pedido
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <tableBoostrapt
                striped
                bordered
                hover
                responsive
                className="tablePopUp"
              >
                <thead>
                  <tr>
                    <th>Nombre del Producto</th>
                    <th>Fecha</th>
                    <th>Precio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Fragment>
                        {!preName
                          ? record.product_name +
                            '  (' +
                            record.payment_year +
                            ')'
                          : preName + ' (' + record.payment_year + ')'}
                      </Fragment>
                    </td>
                    <td>{record.initial_payment}</td>
                    <td>{+record.price / 100}€</td>
                  </tr>
                </tbody>
              </tableBoostrapt>
              <p className="infoResumeOrder">Nombre: {userInfo.name}</p>
              <p className="infoResumeOrder">Apellidos: {userInfo.lastname}</p>
              <p className="infoResumeOrder">
                Forma de pago: {record.payment_type_name}
              </p>
              <p className="infoResumeOrder">
                Estado del pago: {record.state_name}
              </p>
              <div>
                {this.state.showLinkToPDF ? (
                  <PDFDownloadLink
                    document={
                      <ReceiptPdf
                        payment_year={record.payment_year}
                        nombre={userInfo.name}
                        apellido={userInfo.lastname}
                        cantidad={+record.price / 100}
                        nameProduct={!preName ? record.product_name : preName}
                        formPayment={record.payment_type_name}
                        idPedido={record.id}
                        date={this.checkDatePayment(record.initial_payment)}
                        comites={record.comites}
                        tipoPago={record.payment_type}
                        idState={record.id_state}
                        idTemplate={record.id_template}
                        iva={record.iva}
                      />
                    }
                    fileName={'recibo_numero_pedido_' + record.id + '.pdf'}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <button className="button margin-top-20">
                          Cargando Recibo...
                        </button>
                      ) : (
                        <button
                          className="button margin-top-20"
                          onClick={() => this.setnotshowLinkToPDF()}
                        >
                          Descargar Recibo
                        </button>
                      )
                    }
                  </PDFDownloadLink>
                ) : (
                  <Button
                    className="button margin-top-20"
                    onClick={(e) => this.setshowLinkToPDF(e, record.comites)}
                  >
                    Preparar Recibo para descarga
                  </Button>
                )}
                {record.invoice ? (
                  <a
                    href={record.invoice}
                    target="_blank"
                    rel="noreferrer noopener"
                    download={'Factura - ' + record.id}
                  >
                    <Button className="button margin-top-20">
                      Obtener factura
                    </Button>
                  </a>
                ) : null}
              </div>
              <hr></hr>
              {+record.id_template === 5 ? (
                ''
              ) : (
                <span>
                  {record.comites.length > 0 ? (
                    <div>
                      {' '}
                      <p>Listado de comités Incluidos</p>{' '}
                      <tableBoostrapt
                        striped
                        bordered
                        hover
                        className="comiteTableProfile tablePopComite"
                      >
                        <thead>
                          <tr>
                            <th>Nombre</th>
                          </tr>
                          <tbody>
                            {record.comites.map((com, index) => {
                              return (
                                <tr key={index}>
                                  <td>{com.title}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </thead>
                      </tableBoostrapt>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </span>
              )}
            </Modal.Body>
          </Modal>
        </div>
      );
    }
  };
  render() {
    const { userInfo } = this.state;
    //const { beginIban } = this.state;
    //const { endIban } = this.state;
    const { statusPage } = this.state;
    const { userOrders } = this.state;
    const { msgCompleteProfilePersonal } = this.state;
    const { msgCompleteProfileProfesional } = this.state;
    const { msgCompleteProfileSettings } = this.state;
    const { msgCompleteProfile } = this.state;
    const { showLinkToPDF } = this.state;
    const { numComitesPagoPDF } = this.state;
    const { userComitesAdmin } = this.state;
    const { fetchingAdminOrder } = this.state;

    const styles = StyleSheet.create({
      page: { backgroundColor: 'white', padding: 10 },
      header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
      },
      text: {
        fontSize: 12,
        textAlign: 'left',
        color: 'black',
      },
      table: {
        fontSize: 12,
        display: 'table',
        width: '95%',
        borderStyle: 'solid',
      },
      tableRow: {
        fontSize: 12,
        margin: 'auto',
        flexDirection: 'row',
      },
      tableRowBlue: {
        fontSize: 12,
        margin: 'auto',
        flexDirection: 'row',
        backgroundColor: '#2a94ec',
      },
      tableCol: {
        fontSize: 12,
        width: '25%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
      },
      tableCol70: {
        fontSize: 12,
        width: '70%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000000',
      },
      tableCol40: {
        fontSize: 12,
        width: '40%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000000',
      },
      tableCol55: {
        fontSize: 12,
        width: '55%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000000',
      },
      tableCol67: {
        fontSize: 12,
        width: '67%',
      },
      tableCol15: {
        fontSize: 12,
        width: '15%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000000',
      },
      tableCol50: {
        width: '50%',
      },
      tableCol40Transferencia: {
        width: '40%',
        color: 'black',
      },
      tableCol20Blue: {
        backgroundColor: '#2a94ec',
        color: '#FFFFFF',
        width: '20%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000000',
      },
      tableCol100Blue: {
        width: '100%',
        backgroundColor: '#2a94ec',
        color: '#FFFFFF',
      },
      tableCol10: {
        width: '10%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderColor: '#000000',
      },
      tableCol30: {
        width: '30%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000000',
      },
      tableCell: {
        fontSize: 12,
        margin: 'auto',
        marginTop: 5,
      },
      tableCol3: {
        fontSize: 12,
        width: '33.3333%',
        borderLeftWidth: 0,
        borderTopWidth: 0,
      },
      tableCol3blue: {
        fontSize: 12,
        width: '33.3333%',
        borderLeftWidth: 0,
        borderTopWidth: 0,
      },
      whiteSpace: {
        fontSize: 12,
        marginTop: 10,
        borderStyle: 'solid',
        marginBottom: 10,
      },
      bgBlue: {
        fontSize: 12,
        backgroundColor: '#2a94ec',
      },
      alignRight: {
        fontSize: 12,
        textAlign: 'right',
      },
      alignLeft: {
        fontSize: 12,
        textAlign: 'left',
      },
      image: {
        fontSize: 12,
        marginVertical: 15,
        marginHorizontal: 20,
        width: 300,
      },
      tableCell3Right: {
        fontSize: 12,
        textAlign: 'right',
        color: '#FFFFFF',
        padding: '3px',
      },
      tableCell3LeftWhite: {
        fontSize: 12,
        textAlign: 'left',
        color: '#FFFFFF',
        padding: '3px',
      },
      tableCell3RightBlack: {
        fontSize: 12,
        textAlign: 'right',
        color: '#0000000',
        padding: '3px',
      },
      tableCell3RightWhite: {
        fontSize: 12,
        textAlign: 'right',
        color: '#FFFFFF',
        padding: '3px',
      },
      tableCell3LeftBlack: {
        fontSize: 12,
        textAlign: 'left',
        color: '#000000',
        padding: '3px',
      },
      tableCell3CenterWhite: {
        fontSize: 12,
        textAlign: 'center',
        color: '#FFFFFF',
        padding: '3px',
      },
      tableCell3CenterBlack: {
        fontSize: 12,
        textAlign: 'center',
        color: '#000000',
        padding: '3px',
      },
      tableCell3RightBlackTransferencia: {
        fontSize: 12,
        textAlign: 'right',
        color: '#0000000',
        padding: '3px',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000000',
      },
      tableCell3LeftBlackTransferencia: {
        fontSize: 12,
        textAlign: 'left',
        color: '#0000000',
        padding: '3px',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000000',
      },
      footer: {
        flexDirection: 'row',
        position: 'absolute',
        bottom: 15,
        left: 0,
        right: 0,
        paddingLeft: 36,
      },
      footerColumn: {
        width: '100%',
      },
      textFooter: {
        textAlign: 'center',
        fontSize: 12,
      },
    });

    let ReceiptPdf;
    if (showLinkToPDF === true) {
      ReceiptPdf = (props) => (
        <Document>
          <Page size="A4" style={styles.page} wrap>
            <Image
              style={styles.image}
              src={global.urlHome + 'images/fes_logo.jpg'}
            />
            <View style={styles.table}>
              <View style={styles.tableRowBlue}>
                <View style={styles.tableCol3blue}>
                  {+props.idState === 3 ? (
                    <Text style={styles.tableCell3LeftWhite}>
                      RECIBO DEL PEDIDO Nº/{props.idPedido}{' '}
                    </Text>
                  ) : (
                    <Text style={styles.tableCell3LeftWhite}>
                      PEDIDO EN ESPERA DE PAGO Nº/{props.idPedido}{' '}
                    </Text>
                  )}
                </View>
                <View style={styles.tableCol3blue}>
                  <Text style={styles.tableCell3LeftWhite}></Text>
                </View>
                <View style={styles.tableCol3blue}>
                  <Text style={styles.tableCell3RightWhite}>{props.date}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell3LeftBlack}>
                    NIF: {userInfo.nif}
                  </Text>
                </View>
                <View style={styles.tableCol67}>
                  <Text style={styles.tableCell3LeftBlack}>
                    {props.nombre} {props.apellido}
                  </Text>
                  <Text style={styles.tableCell3LeftBlack}>
                    {userInfo.address_1} {userInfo.address_2}{' '}
                    {userInfo.postal_code}
                  </Text>
                  <Text style={styles.tableCell3LeftBlack}>
                    {userInfo.city}
                  </Text>
                  <Text style={styles.tableCell3LeftBlack}>
                    {userInfo.region} {userInfo.country}
                  </Text>
                </View>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell2Right}></Text>
                </View>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRowBlue}>
                <View style={styles.tableCol40}>
                  <Text style={styles.tableCell3LeftWhite}>CONCEPTO</Text>
                </View>
                <View style={styles.tableCol15}>
                  <Text style={styles.tableCell3CenterWhite}>Cantidad</Text>
                </View>
                <View style={styles.tableCol15}>
                  <Text style={styles.tableCell3CenterWhite}>B.I.</Text>
                </View>
                <View style={styles.tableCol15}>
                  <Text style={styles.tableCell3CenterWhite}>IVA</Text>
                </View>
                <View style={styles.tableCol15}>
                  <Text style={styles.tableCell3CenterWhite}>Total</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol40}>
                  <Text style={styles.tableCell3LeftBlack}>
                    {props.nameProduct + ' ' + props.payment_year}
                  </Text>
                </View>
                <View style={styles.tableCol15}>
                  <Text style={styles.tableCell3CenterBlack}>1</Text>
                </View>
                <View style={styles.tableCol15}>
                  <Text style={styles.tableCell3CenterBlack}>
                    {+props.cantidad === 0
                      ? '0€'
                      : props.cantidad -
                        numComitesPagoPDF * global.priceSingleComite -
                        (props.iva
                          ? (props.cantidad * (props.iva / 100)).toFixed(2)
                          : 0) +
                        '€'}
                  </Text>
                </View>
                <View style={styles.tableCol15}>
                  <Text style={styles.tableCell3CenterBlack}>
                    {!props.iva || +props.iva === 0
                      ? 'EXENTO'
                      : props.iva + '%'}
                  </Text>
                </View>
                <View style={styles.tableCol15}>
                  <Text style={styles.tableCell3CenterBlack}>
                    {+props.cantidad === 0
                      ? '0€'
                      : props.cantidad -
                        numComitesPagoPDF * global.priceSingleComite +
                        '€'}
                  </Text>
                </View>
              </View>
              {+numComitesPagoPDF === 0 ? null : (
                <View style={styles.tableRow}>
                  <View style={styles.tableCol40}>
                    <Text style={styles.tableCell3LeftBlack}>
                      Comité/Grupo de trabajo extra
                    </Text>
                  </View>
                  <View style={styles.tableCol15}>
                    <Text style={styles.tableCell3CenterBlack}>
                      {numComitesPagoPDF}
                    </Text>
                  </View>
                  <View style={styles.tableCol15}>
                    <Text style={styles.tableCell3CenterBlack}>
                      {global.priceSingleComite}€
                    </Text>
                  </View>
                  <View style={styles.tableCol15}>
                    <Text style={styles.tableCell3CenterBlack}>EXENTO</Text>
                  </View>
                  <View style={styles.tableCol15}>
                    <Text style={styles.tableCell3CenterBlack}>
                      {numComitesPagoPDF * global.priceSingleComite}€
                    </Text>
                  </View>
                </View>
              )}
            </View>
            <Text style={styles.whiteSpace}></Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol50}>
                  <Text style={styles.tableCell3LeftWhite}></Text>
                </View>
                <View style={styles.tableCol20Blue}>
                  <Text style={styles.tableCell3RightWhite}>Subtotal</Text>
                </View>
                <View style={styles.tableCol30}>
                  <Text style={styles.tableCell3LeftBlack}>
                    {props.cantidad -
                      (props.iva
                        ? (props.cantidad * (props.iva / 100)).toFixed(2)
                        : 0)}
                    €
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol50}>
                  <Text style={styles.tableCell3LeftWhite}></Text>
                </View>
                <View style={styles.tableCol20Blue}>
                  <Text style={styles.tableCell3RightWhite}>
                    {props.iva ? 'IVA' : 'Exenta IVA(s/art.20.1.12)'}
                  </Text>
                </View>
                <View style={styles.tableCol30}>
                  <Text style={styles.tableCell3LeftBlack}>
                    {' '}
                    {props.iva
                      ? (props.cantidad * (props.iva / 100)).toFixed(2) + '€'
                      : '0,00€'}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol50}>
                  <Text style={styles.tableCell3LeftWhite}></Text>
                </View>
                <View style={styles.tableCol20Blue}>
                  <Text style={styles.tableCell3RightWhite}>Total</Text>
                </View>
                <View style={styles.tableCol30}>
                  <Text style={styles.tableCell3LeftBlack}>
                    {props.cantidad}€
                  </Text>
                </View>
              </View>
            </View>
            <Text style={styles.whiteSpace}></Text>
            {+props.tipoPago === 2 ? (
              +props.idState !== 3 ? (
                <View>
                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol100Blue}>
                        <Text style={styles.tableCell3CenterWhite}>
                          Factura pendiente de pago por Transferencia
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                      <View style={styles.tableCol40Transferencia}>
                        <Text style={styles.tableCell3RightBlackTransferencia}>
                          Beneficiario
                        </Text>
                      </View>
                      <View style={styles.tableCol40Transferencia}>
                        <Text style={styles.tableCell3LeftBlackTransferencia}>
                          FES
                        </Text>
                      </View>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                    </View>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                      <View style={styles.tableCol40Transferencia}>
                        <Text style={styles.tableCell3RightBlackTransferencia}>
                          Concepto
                        </Text>
                      </View>
                      <View style={styles.tableCol40Transferencia}>
                        <Text style={styles.tableCell3LeftBlackTransferencia}>
                          FES/{props.idPedido}
                        </Text>
                      </View>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                    </View>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                      <View style={styles.tableCol40Transferencia}>
                        <Text style={styles.tableCell3RightBlackTransferencia}>
                          Banco
                        </Text>
                      </View>
                      <View style={styles.tableCol40Transferencia}>
                        <Text style={styles.tableCell3LeftBlackTransferencia}>
                          CAIXABANK
                        </Text>
                      </View>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                    </View>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                      <View style={styles.tableCol40Transferencia}>
                        <Text style={styles.tableCell3RightBlackTransferencia}>
                          IBAN
                        </Text>
                      </View>
                      <View style={styles.tableCol40Transferencia}>
                        <Text style={styles.tableCell3LeftBlackTransferencia}>
                          ES81 2100 3100 5013 0019 1562
                        </Text>
                      </View>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                    </View>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                      <View style={styles.tableCol40Transferencia}>
                        <Text style={styles.tableCell3RightBlackTransferencia}>
                          SWIFT/BIC
                        </Text>
                      </View>
                      <View style={[styles.tableCol40Transferencia]}>
                        <Text style={styles.tableCell3LeftBlackTransferencia}>
                          CAIXESBBXXX
                        </Text>
                      </View>
                      <View style={styles.tableCol10}>
                        <Text></Text>
                      </View>
                    </View>
                  </View>
                </View>
              ) : null
            ) : null}

            <View style={styles.footer} fixed>
              <View style={styles.footerColumn}>
                <Text style={styles.textFooter}>
                  Federación Española de Sociología · G-28918514 · Montalbán, 8
                  - 28014 Madrid
                </Text>
                <Text style={styles.textFooter}>
                  Tel./Fax: 91 5232741 · E-mail: info@fes-sociologia.com{' '}
                </Text>
              </View>
            </View>
          </Page>
        </Document>
      );
    }

    if (statusPage === 1) {
      localStorage.removeItem('access_token');
      return <Redirect to="/login" />;
    }

    const personalUserInfo = (
      <Form onSubmit={this.handleSubmitPersonalInfo}>
        <Row>
          <Col lg={6} sm={12}>
            <Form.Group controlId="nameUser">
              <Form.Label>Nombre*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                name="nameUser"
                required
                defaultValue={userInfo.name}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="lastNameUser">
              <Form.Label>Apellidos*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Apellidos"
                name="lastNameUser"
                required
                defaultValue={userInfo.lastname}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="dniUser" className="fieldUnderText">
              <Form.Label>DNI*</Form.Label>
              <Form.Control
                required
                type="text"
                name="dniUser"
                placeholder="DNI"
                defaultValue={userInfo.nif}
              />
            </Form.Group>
            <p className="smallTextExplanation">
              El DNI debe estar formado solo por letras y números. No puede
              contener caractéres especiales o espacios en blanco.
            </p>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="emailUser">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                readOnly={true}
                required
                type="email"
                name="emailUser"
                placeholder="email"
                defaultValue={userInfo.email}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="yearBornUser">
              <Form.Label>Año de Nacimiento</Form.Label>
              <Form.Control
                type="date"
                placeholder="Año de Nacimiento"
                name="yearBornUser"
                defaultValue={userInfo.birthdate}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="bornCountryUser">
              <Form.Label>Pais de nacimiento</Form.Label>
              <Form.Control
                type="text"
                placeholder="Pais de nacimiento"
                name="bornCountryUser"
                defaultValue={userInfo.country}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="genderUser">
              <Form.Label>Género(Opcional)</Form.Label>
              <Form.Control
                as="select"
                name="genderUser"
                ref="genderUser"
                onChange={this.changeOptionGender}
                value={userInfo.gender}
              >
                <option value="01">-Seleccione una opción-</option>
                <option value="1">Mujer</option>
                <option value="0">Hombre</option>
                <option value="2">Otros</option>
                <option value="3">No desea indicarlo</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="adressUser">
              <Form.Label>Dirección*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Dirección"
                name="adressUser"
                required
                defaultValue={userInfo.address_1}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="cpUser">
              <Form.Label>Código Postal*</Form.Label>
              <Form.Control
                type="text"
                name="cpUser"
                placeholder="Código Postal"
                required
                defaultValue={userInfo.postal_code}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="cityUser">
              <Form.Label>Ciudad*</Form.Label>
              <Form.Control
                type="text"
                name="cityUser"
                placeholder="Ciudad"
                required
                defaultValue={userInfo.city}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="stateUser">
              <Form.Label>Provincia/Región*</Form.Label>
              <Form.Control
                type="text"
                name="stateUser"
                placeholder="Provincia/Región"
                required
                defaultValue={userInfo.region}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="residenceUser">
              <Form.Label>País de residencia*</Form.Label>
              <Form.Control
                type="text"
                name="residencesUser"
                placeholder="País de residencia"
                defaultValue={userInfo.country}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="phoneMobileUser">
              <Form.Label>Teléfono 1*</Form.Label>
              <Form.Control
                type="tel"
                name="mobileUser"
                placeholder="Teléfono 1"
                defaultValue={userInfo.phone}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="phoneHomeUser">
              <Form.Label>Teléfono 2</Form.Label>
              <Form.Control
                type="tel"
                name="telUser"
                placeholder="Teléfono 2"
                defaultValue={userInfo.landline}
              />
            </Form.Group>
          </Col>
          <Col sm={12} xs={12}>
            <Button ref="btnPersonal" variant="primary" type="submit">
              Guardar información
            </Button>
          </Col>
        </Row>
      </Form>
    );

    const settingsUserInfo = (
      <Form onSubmit={this.handleSettingsProfile}>
        <Row>
          <Col lg={6} sm={12}>
            <Form.Group controlId="publicProfile">
              <Form.Label as="legend">
                1*¿Desea que aparezca su perfil en la web?
              </Form.Label>
              <Form.Check
                type="radio"
                label="Si"
                value="0"
                name="publicProfile"
                id="publicProfileYes"
                ref="publicProfile"
                checked={userInfo.type_status === '0'}
                onChange={this.publicProfileRadio}
              />
              <Form.Check
                type="radio"
                label="No"
                value="1"
                name="publicProfile"
                id="publicProfileNo"
                ref="publicProfile"
                checked={userInfo.type_status === '1'}
                onChange={this.publicProfileRadio}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="joinNewsletter">
              <Form.Label as="legend">
                2*¿Quieres recibir la newsletter de FES?
              </Form.Label>
              <Form.Check
                type="radio"
                label="Si"
                value="1"
                name="joinNewsletter"
                ref="joinNewsletter"
                id="newsletterConfirmationYes"
                checked={userInfo.newsletter === '1'}
                onChange={this.changeNewsletterJoin}
              />
              <Form.Check
                type="radio"
                label="No"
                value="0"
                name="joinNewsletter"
                ref="joinNewsletter"
                id="newsletterConfirmationNo"
                checked={userInfo.newsletter === '0'}
                onChange={this.changeNewsletterJoin}
              />
            </Form.Group>
          </Col>
          <Col lg={12}>
            Si desea darse de baja de su afiliación a FES puede escribir a la
            secretaría (
            <a href="mailto:info@fes-sociologia.com">info@fes-sociologia.com</a>
            ).
            <hr></hr>
          </Col>

          <Col lg={12}>
            <p className="small">
              1* -> La opción de hacer público su perfil académico y profesional
              sólo está disponible para socios de FES. En el apartado de
              Miembros individuales de la web aparecerá un enlace en el nombre y
              apellidos del asociado/a remitiendo a los datos cumplimentados en
              su área profesional{' '}
            </p>
            <p className="small">
              2* -> Quiero recibir el boletín periódico con contenidos de
              actualidad, noticias de FES, sus asociados y otras entidades de
              interés.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Button ref="btnSettings" variant="primary" type="submit">
              Guardar información
            </Button>
          </Col>
        </Row>
      </Form>
    );

    const profesionalUserInfo = (
      <Form onSubmit={this.handleSubmitProfesionalInfo}>
        <Row>
          <Col lg={6} sm={12}>
            <Form.Group controlId="levelStudiesUser">
              <Form.Label>Nivel de estudios más alto alcanzado*</Form.Label>
              <Form.Control
                as="select"
                value={userInfo.education}
                name="education"
                ref="education"
                onChange={this.changeOptionLvlStudies}
              >
                <option value="01">-Seleccione una opción-</option>
                <option value="No universitarios">
                  Sin Estudios Universitarios
                </option>
                <option value="Grado o licenciatura universitaria">
                  Grado o licenciatura universitaria
                </option>
                <option value="Posgrado/Doctor">Posgrado / Doctor</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            {' '}
            <Form.Group controlId="academic_degreeExtraUser">
              <Form.Label>Titulación*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Titulación"
                defaultValue={userInfo.academic_degree}
                name="academic_degree"
                ref="academic_degree"
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            {' '}
            <Form.Group controlId="jobUser" name="job" ref="job">
              <Form.Label>Puesto de trabajo*:</Form.Label>
              <Form.Control
                type="input"
                placeholder="Puesto de trabajo"
                name="job"
                defaultValue={userInfo.job}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="actualInstitutionUser">
              <Form.Label>Institución actual*</Form.Label>
              <Form.Control
                as="select"
                value={userInfo.org}
                name="org"
                ref="org"
                onChange={this.changeOptionActualInstitution}
              >
                <option value="01">-Seleccione una opción-</option>
                <option value="universitaria">Universitaria</option>
                <option value="Profesional">Profesional</option>
                <option value="Otras">Otras</option>
                <option value="Ninguna">Ninguna</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            {' '}
            <Form.Group controlId="insstitutionExtraUser">
              <Form.Label>Institucion actual detalle</Form.Label>
              <Form.Control
                type="text"
                placeholder="Institución actual"
                defaultValue={userInfo.org_extra}
                name="org_extra"
                ref="org_extra"
              />
            </Form.Group>
          </Col>

          <Col lg={6} sm={12}>
            <Form.Group controlId="shortCVUser">
              <Form.Label>Adjunte su CV:</Form.Label>
              <Form.Control
                onChange={(e) => console.log(e.target.files)}
                type="file"
                name="doc_url"
                placeholder="Fichero a subir"
              />
            </Form.Group>
          </Col>
          {!userInfo.doc_url ? null : (
            <Col lg={6} sm={12}>
              <Form.Label>Ver CV:</Form.Label>
              <div>
                <a
                  href={userInfo.doc_url}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Link a CV
                </a>
              </div>
            </Col>
          )}

          <Col lg={6} sm={12}>
            {' '}
            <Form.Group controlId="linkedinUser" name="linkedin" ref="linkedin">
              <Form.Label>Link a CV online:</Form.Label>
              <Form.Control
                type="url"
                placeholder="Linkedin, otros…"
                name="linkedin"
                defaultValue={userInfo.cv_description}
              />
            </Form.Group>
          </Col>

          <Col sm={12} xs={12}>
            <Button ref="btnProfesional" variant="primary" type="submit">
              Guardar información
            </Button>
          </Col>
        </Row>
      </Form>
    );

    /*const bankUserInfo = (
			<Form onSubmit={this.handleSubmitBankInfo}>
				<Row>
					<Col lg={12}>
						<p>
							<b>Datos opcionales</b>
						</p>
						<p>
							Puede meter sus datos de cuenta si quiere realizar alguna compra y
							quiere hacerlo a través de "domiciliación bancaria" o bien se le
							pedirán estos datos en el mismo momento de realizar la compra.
						</p>
					</Col>

					<Col lg={6} sm={12}>
						<Row>
							<Col lg={3}>
								{" "}
								<Form.Group
									controlId="ibanBegin"
									name="ibanBegin"
									ref="ibanBegin"
								>
									<Form.Label>1* IBAN*:</Form.Label>
									<Form.Control
										type="input"
										placeholder="iban"
										name="ibanBegin"
										ref="ibanBegin"
										maxLength="4"
										required
										defaultValue={beginIban}
										onChange={this.handleChangeFocus.bind(this)}
									/>
								</Form.Group>
							</Col>
							<Col lg={9}>
								{" "}
								<Form.Group>
									<Form.Label>2* Número de cuenta*:</Form.Label>
									<Form.Control
										id="ibanEnd"
										type="input"
										placeholder="iban"
										name="ibanEnd"
										pattern="[0-9]*"
										ref="ibanEnd"
										maxLength="20"
										required
										defaultValue={endIban}
									/>
								</Form.Group>
							</Col>
						</Row>
					</Col>
					<Col lg={6} sm={12}>
						{" "}
						<Form.Group controlId="swift" name="swift" ref="swift">
							<Form.Label>Swift*:</Form.Label>
							<Form.Control
								type="input"
								placeholder="Swift"
								name="swift"
								ref="swift"
								required
								defaultValue={userInfo.swift}
							/>
						</Form.Group>
					</Col>
					<Col lg={12}>
						<p className="small">
							1* -> El IBAN se compone de 4 caracteres. Los dos Primeros
							corresponden al código del país(ES es el código de España) y los 2
							últimos son los digitos de control.{" "}
						</p>
						<p className="small">
							2* -> El número de cuenta se compone de un total de 20 digitos.
						</p>
					</Col>
					<Col sm={12} xs={12}>
						<Button ref="btnBank" variant="primary" type="submit">
							Guardar información
						</Button>
					</Col>
				</Row>
			</Form>
		);
*/
    const modal = (
      <div>
        <Modal
          show={this.state.showModal2}
          onHide={() => this.setState({ showModal2: false })}
        >
          modal two content..
        </Modal>
      </div>
    );
    console.log(fetchingAdminOrder)
    const sidebar = !fetchingAdminOrder ? (
      <SidebarProfile
        userInfo={userInfo}
        userOrders={userOrders}
        userComitesAdmin={userComitesAdmin}
        votationActive={this.state.phase}
        votationActiveAssembly={this.state.phaseAssembly}
        comitesUserGet={this.state.comitesUserGet}
        newSidebar={this.state.newSidebar}
        orderForSidebar={this.state.orderForSidebar}
        renovableOrder={this.state.renovableOrder}
        candidatureComiteExecutive={this.state.candidatureComiteExecutive}
        isColective="0"
        {...this.state}
      ></SidebarProfile>
    ) : (
      <p>Cargando</p>
    );
    const renovationButton = userOrders.map((UO, index) => {
      if (+UO.id_state === 3) {
        return this.checkIfRenovable(UO.id) === true ? (
          +UO.id_template === 6 ? (
            <div key={index}>
              <p>
                Puede iniciar el proceso de renovación haciendo click en el
                siguiente botón
              </p>
              <Link
                to={{
                  pathname: `/producto/3`,
                  state: {
                    productID_confirmation: UO.id_product,
                    renov_id: UO.id,
                    renovationTime: true,
                    id_product_renov: UO.id_product,
                    oldPayment: UO.payment_type,
                  },
                }}
              >
                {' '}
                <div className="button">Iniciar proceso de renovación</div>
              </Link>
              <Divider dashed></Divider>
            </div>
          ) : (
            <div key={index}>
              <p>
                Puede iniciar el proceso de renovación haciendo click en el
                siguiente botón
              </p>
              <Link
                to={{
                  pathname: `/producto/${UO.id_product}`,
                  state: {
                    productID_confirmation: UO.id_product,
                    renov_id: UO.id,
                    renovationTime: true,
                    oldPayment: UO.payment_type,
                  },
                }}
              >
                {' '}
                <div className="button">Iniciar proceso de renovación</div>
              </Link>
              <Divider dashed></Divider>
            </div>
          )
        ) : null;
      } else {
        return null;
      }
    });

    const { islogged } = this.state;
    if (islogged === 0) {
      //return <Redirect to="/" />;
    } else {
    }

    const title = (
      <TopImageTitle
        title="CUENTA DE USUARIO"
        subtitle="Toda tu información"
        bread01="Perfil"
      ></TopImageTitle>
    );

    const columnsUserOrders = [
      {
        title: 'Nº Pedido',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Fecha',
        dataIndex: 'initial_payment',
        key: 'initial_payment',
        render: (text, record) => this.checkDatePayment(text),
      },
      {
        title: 'Productos',
        dataIndex: 'product_name',
        key: 'product_name',
        render: (text, record) => (
          <div>
            <p>{text + ' (' + record.payment_year + ') '}</p>
            <p>
              {' '}
              {record.show_add_comites === true && this.state.allowAddComites ? (
                this.freeSpotAvaliable(
                  record.comites,
                  record.id,
                  record.id_state
                ) === 1 ? (
                  <Link
                    to={{
                      pathname: `/producto/79`,
                      state: {
                        statusDoc: 1,
                        id_payment: record.id,
                        typeOfAdd: 1,
                        userID: this.state.userInfo.id,
                      },
                    }}
                  >
                    Añadir comités
                  </Link>
                ) : this.freeSpotAvaliable(
                    record.comites,
                    record.id,
                    record.id_state
                  ) === 0 ? (
                  <Link
                    to={{
                      pathname: `/producto/79`,
                      state: {
                        statusDoc: 1,
                        id_payment: record.id,
                        typeOfAdd: 0,
                        userID: this.state.userInfo.id,
                      },
                    }}
                  >
                    Añadir comités Gratuitos
                  </Link>
                ) : (
                  ''
                )
              ) : (
                <span></span>
              )}
            </p>
          </div>
        ),
      },
      {
        title: 'Estado',
        dataIndex: 'state_name',
        key: 'state_name',
      },
      {
        title: 'Mensaje',
        dataIndex: '',
        key: 'y',
        render: (text, record) => (
          <div>
            {this.messageByState(
              record.id_state,
              record.id,
              record.renov_id,
              record.id_product,
              record.observations
            )}
          </div>
        ),
      },
      {
        title: 'Información de pedido / Factura',
        dataIndex: '',
        key: 'y',
        render: (text, record) => (
          <div>
            {this.orderInformationInvoice(
              record.id_state,
              record.id,
              record.renov,
              record.id_product,
              record.observations,
              record,
              this.state.userInfo,
              record.id_template === '6'
                ? 'Socio/a  de Territorial inscrito/a a comités'
                : '',
              ReceiptPdf
            )}
          </div>
        ),
      },
    ];

    return (
      <div>
        <SEO title="Perfil de Usuario" key={'SEO-user'}></SEO>
        <hr className="whiteSpace"></hr>
        {title}
        <section>
          <Container>
            <Row>
              <Col lg={3} sm={12}>
                {modal}
                {sidebar}
              </Col>

              <Col lg={9} sm={12}>
                {msgCompleteProfilePersonal ? (
                  <div className="alert alert-warning" role="alert">
                    <p>{msgCompleteProfilePersonal}</p>
                  </div>
                ) : msgCompleteProfileProfesional ? (
                  <div className="alert alert-warning" role="alert">
                    {msgCompleteProfileProfesional}
                  </div>
                ) : msgCompleteProfileSettings ? (
                  <div className="alert alert-warning" role="alert">
                    <p>{msgCompleteProfileSettings}</p>
                  </div>
                ) : null}
                {msgCompleteProfile ? null : null}
                {this.state.msgRenovations ? (
                  <div className="alert alert-warning" role="alert">
                    <p>{this.state.msgRenovations}</p>
                  </div>
                ) : null}
                {this.state.messageCompletetoAfilitation === 1 ? (
                  <div className="alert alert-warning" role="alert">
                    <p>
                      Ha completado todos los datos requeridos. Puede proceder
                      con su afiliación a través del siguiente enlace:
                    </p>
                    <a href="/afiliate" className="button">
                      Afiliación
                    </a>
                  </div>
                ) : (
                  ''
                )}
                <Tabs
                  className="tabsProfile"
                  defaultActiveKey="personalData"
                  activeKey={this.state.key}
                  onSelect={this.handleSelect}
                >
                  <Tab eventKey="personalData" title="Datos Personales">
                    <div className="profileUserInfo"> {personalUserInfo}</div>{' '}
                  </Tab>
                  <Tab eventKey="profesionalData" title="Datos Profesionales">
                    {profesionalUserInfo}
                  </Tab>
                  <Tab eventKey="settingData" title="Ajustes">
                    {settingsUserInfo}
                  </Tab>
                  <Tab eventKey="afiliationData" title="Pedidos">
                    <div>
                      {+this.state.alreadyRenovate === 1
                        ? ''
                        : renovationButton}
                      <div hidden>
                        {' '}
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Nº Pedido</th>
                              <th>Fecha</th>
                              <th>Productos</th>
                              <th>Estado</th>
                              <th>Mensaje</th>
                              <th>Información de pedido / Factura</th>
                            </tr>
                          </thead>
                          <tbody>{/*afiliations*/}</tbody>
                        </Table>
                      </div>

                      <Col lg={12}>
                        <Table
                          columns={columnsUserOrders}
                          onChange={this.onChange}
                          dataSource={this.state.userOrders}
                          className="isoSearchableTable"
                          key={this.state.comitesUserGet}
                        />
                      </Col>
                    </div>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}
export default withRouter(BasicUser);
